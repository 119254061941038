
.dd-title{
    text-align: left;
    font: normal 30px AvenirBook;
    letter-spacing: 0px;
    color: #199094;
    opacity: 0.9;
    margin-left:50px;
}
.dd-div{
    background: #F6FAFB 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    min-height:100vh;
    margin:30px 70px 50px 50px;
    padding-top:10px;
}
.div-1{
    padding:0 32px 50px 42px;
    text-align:left;
}
.attr{
    text-align: left;
    font: normal normal 600 20px AvenirBook;
    letter-spacing: 0px;
    color: #111111;
    opacity: 1;
    vertical-align: top;
}
.value{
    text-align: left;
    font: normal normal 500 20px AvenirBook;
    letter-spacing: 0px;
    color: #111111;
    opacity: 1;
    word-break: break-all;
}
.sms{
    color: #111111;
opacity: 0.5;
}
.disable-usr{
    margin-top:15px;
    background: #199094 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    opacity: 1;
    padding:15px 25px;
    color: #F7FCFD !important;
    font-weight: 400;
}
.disable-modal .ant-modal-content .ant-modal-header {
    border:0;
    height:50px;
    padding:15px;
    border-radius:15px;
}
.disable-modal .ant-modal-content {
    border-radius:15px;
}
.disable-modal .ant-modal-content .ant-modal-title {
    font: normal normal 500 22px/9px AvenirBook;
    letter-spacing: 0px;
    color: #76CACE;
    opacity: 0.9;
    text-align:center;
    margin-top:15px;
}
.disable-modal .ant-modal-content .ant-modal-body {
    padding:24px 35px 24px 0;
}
.disable-modal .ant-modal-content .ant-modal-footer {
    border:0;
    display:flex;
    justify-content:space-between;
    padding:0px 35px 20px;
}
.modal-btn{
    background: #69C4C8 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    opacity: 1;
    padding: 11px 24px;
    color: #F7FCFD !important;
    height: 45px;
    border: 0 !important;
}
.div-com{
    margin:0 18px 0 42px;
    padding:40px 0 50px 0;
    border-top: solid 2px #7070701e;
    text-align:left;
}
.divs-title{
    text-align: left;
font: normal normal bold 24px AvenirBook;
letter-spacing: 0px;
color: #111111;
margin-bottom:40px;
}
.graph-btn{
        background: #69C4C8 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 15px;
        opacity: 1;
        padding:15px 45px;
        color: #F7FCFD !important;
        outline:0 !important;
        border:0 !important;
}
.scroll-div{
    height: 250px;
    overflow-y: scroll;
}
.ref-div{
    padding:15px 20px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 15px;
    font: normal normal 500 16px AvenirBook;
    text-align:left;
    margin-bottom:30px;
}
.div-5 .ant-table{
    background: transparent;
    font: normal normal 500 20px AvenirBook;
    color: #111111;
}
.div-5 .ant-table-content .ant-table-tbody > tr.ant-table-expanded-row > td, tr.ant-table-expanded-row:hover > td {
    background: transparent !important;
}
.div-5 .ant-table-content .ant-table-thead > tr > th{
    background: transparent !important;
    border-bottom: 1px solid #f0f0f0 !important;
    font: normal normal 700 20px AvenirBook !important;
    color: #111111 !important;
}
.div-5 .ant-table-content .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f0f0 !important;
    height:55px;
    }
.div-5 .ant-table-content .ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td,
.div-5 .ant-table-content .ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td,
.div-5 .ant-table-content .ant-table-thead>tr:hover:not(.ant-table-expanded-row)>td,
.div-5 .ant-table-content .ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td {
    background: unset;
}
.status-select{
    font: normal normal 500 20px AvenirBook !important;
    color: #111111 !important;
}
.recharts-surface{
    overflow:visible !important;
    background:#76CACE08;
}
.tooltip-div{
    background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 1px 4px #0000001A;
border: 0.5px solid #CCD6E1;
opacity: 1;
padding: 20px;
}
.tooltip-div p{

font: normal normal normal 13px/16px AvenirBook;
letter-spacing: 0px;
color: #707070;
}