.notification-bar {
  position:relative;
  background: #ace3e6 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 15px;
  height: 50px;
  font: normal normal 808 16px/12px AvenirBook;
  padding: 17px;
  color: white !important;
  float: right;
  width: 210px;
  border:0 !important;
}

/*Notification CSS*/

.noti-odiv{
    position: absolute;
    top: 50px;
    right: 0;
    z-index:10;
    background: #ACE3E6 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    height:173px;
    padding:10px 7px 0px 7px;
    overflow-y:scroll;
    width:210px;
    cursor: default;
}
.noti-odiv {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.noti-odiv::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}
.noti-name{
    font: normal normal 500 14px/14px AvenirBook;
    letter-spacing: 0px;
    color: #FFFFFF;
        display: flex;
    justify-content: space-between;
}
.noti-date{
    font: normal normal 500 10px/14px AvenirBook;
    letter-spacing: 0px;
    color: #69C4C8;
}
.noti-content{
    font: normal normal 500 12px/14px AvenirBook;
    letter-spacing: 0px;
    color: #606368;
    margin-bottom:10px;
    text-align:left;
        margin-top: 5px;
}

/*CSS ends*/

.name-header {
  font: normal normal normal 30px/12px AvenirBook;
  text-align: left;
  color: white;
  padding: 2% 0 1% 24%;
}

.active-users {
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 0.65;
  color: #51e34c;
  font: normal normal 600 11px/7px AvenirBook;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 0.65;
  color: #51e34c;
  font: normal normal 600 11px/7px AvenirBook;
  border-radius: 20px;
  padding: 3px 13px;
}

.cohort-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000001a;
  border-radius: 15px !important;
  display: flex;
  margin: 0 25px 40px;
  overflow: auto;
}
.cohort-box::-webkit-scrollbar {
  display: block;
}

.avg-time {
  background: #ace3e6 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #0000001a;
  opacity: 0.6;
  margin: 15px 8px;
  padding: 10px 5px;
  min-width: 210px;
}
.table-div {
  /* margin-top: 30px; */
  padding: 20px;
}
.table-div .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
  overflow-wrap: normal !important;
  width: 140px;
}
.table-div .ant-table-thead > tr > th {
  background-color: #ffffff !important;
  height: 40px;
  border-bottom: solid 1px #ffffff;
  font-weight: 700;
  font-size: 18px;
}
.coach-row {
  background-color: #ace3e61e !important;
  margin-bottom: 10px;
  height: 60px;
  overflow-wrap: normal !important;
  width: 140px;
}
/*.ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td,
.ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td,
.ant-table-thead>tr:hover:not(.ant-table-expanded-row)>td,
.ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td {
    background: unset;
}*/

.sms-span .ant-select-selector{
    padding:0 !important;
}
.sms-span .ant-select-arrow{
    right:10px !important;
}
.lco-inp{
  border: 0 !important;
  width: 100px !important;
  /* opacity: 50%; */
  color:'grey'!important;
  background: transparent !important;
  font-size: 12px !important;
  padding:0 !important;
}
.lc-input {
  border-radius: 8px !important;
  border: 0 !important;
  width: 100% !important;
}
.status-btn {
  background: #51e34c1e;
  border-radius: 4px;
  color: #51e34c;
  font-size: 14px;
  border: 0 !important;
  outline: 0 !important;
  padding: 4px;
  width: 100%;
  text-align: center;
}
.dropdown-select {
  background-color: #ffffff;
  border-radius: 8px !important;
}
.lock {
  color: #585858 !important;
}
.unlock {
  color: #193347 !important;
}
.rev {
  color: #ffec3e !important;
}
.ongo {
  color: #C8B700 !important;
}
.done {
  color: #0A8A1D !important;
}

.continuous {
  color: #864ce3 !important;
}

/* .tr-a{
  background-color:rgb(209, 240, 209);
} */
.left {
  border-radius: 20px 0px 0px 20px !important;
  background-color: rgb(209, 240, 209);
}

tr.tr-a td {
  /* background-color: white; */
  /* border-bottom: 0px solid white !important;
  border-top: 15px solid white;
  border-right: 15px solid white; */
  border: 5px solid white !important;
  
  /* border: 1px solid #E5F2F3 !important; */
  background-color: #F6FAFB !important;
  border-radius: 20px !important;
}
.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td{
  padding: 8px 10px !important;
}
/* .table-div .ant-table-tbody > tr.tr-a > td {
  border: 1px solid #E5F2F3 !important;
  background-color: #F6FAFB !important;
  border-radius: 8px !important;
} */
.midway-btn{
  outline:0 !important;
  border:0 !important;
  border-radius: 8px !important;
  background: #199094 0% 0% no-repeat padding-box !important;
  color:#ffffff !important;
  font-weight:bold !important;
}
span.ant-picker-suffix .anticon.anticon-calendar svg path{
  fill: #199094 !important;
}

.imp-image-r {
  background: url("https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/coachDashboardViewProfile.svg") no-repeat;
  /* float: right; */
  cursor: pointer;
  padding-right: 20px;
}

.imp-image-l {
  background: url("https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/coachDashboardDrillDown.svg") no-repeat;
  float: left;
  cursor: pointer;
}

.table-div  .ant-table-thead > tr > th,
.table-div  .ant-table-content .ant-table-tbody > tr > td,
.table-div  .ant-table-content .ant-table tfoot > tr > th,
.table-div  .ant-table-content .ant-table tfoot > tr > td {
  position: relative;
  padding: 8px 10px !important;
  overflow-wrap: break-word;
}
/* .ant-table-tbody > tr > td{
  pa
} */

.table-div  .ant-table-content .ant-table-thead th.ant-table-column-has-sorters {
    padding: 0 16px;
}

.table-div .ant-table-column-sorters {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
}








.card {
  background: #fdfdfd;
  border-radius: 4px;
  height: 55vh;
  width: 60vw;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
  overflow: hidden;
}

@media only screen and (min-width: 1000px) {
  .card {
    flex-direction: row-reverse;
  }
  .card img.birthday {
    width: 100%;
    max-width: 50vw;
    max-height: unset;
  }
}

@media only screen and (max-height: 640px) {
  .card {
    flex-direction: row-reverse;
  }
  .card img.birthday {
    width: 100%;
    max-width: 50vw;
    max-height: unset;
  }
}

img.birthday {
  max-height: 40vh;
}

.text {
  padding: 1em;
}

.muted {
  opacity: 0.8;
}

.spaced {
  height: 100px;
}

/* MY csss for header */

.table-div .ant-table-header{
  position: sticky;
  top: 0;
  z-index: 1000;
}

.header-coach-dashboard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  background-color: #1a1a1a;
  color: white;
  max-height: 72px;
}

.header-inner-left{
  display: flex;
  width: 40%;
  justify-content: space-between;
  align-items: center;
  height: auto;
}
.user-name-header-top{
  font-size: 20px;
  line-height: 24px;
  font-family: 'AvenirBook';
font-weight: 400;
color: #FFFFFF;
}

.coach-dashboard-text ,.right-container-user-name{
  font-size: 14px;
  line-height: 22px;
  font-family: 'AvenirBook';
font-weight: 600;
color: #FFFFFF;
margin-bottom:0px;
}

.user-profile-coach-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}


.dropdown-arrow {
  font-size: 0.8rem;
}

.metrics-container {
  padding: 16px 0px 16px 24px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.metrics-scroll {
  display: flex;
  gap: 23px;
  overflow-x: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 16px 0px;
}

.scroll-button:focus {
  outline: none;
  box-shadow: none;
}

.scroll-button {
  flex-shrink: 0;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  transition: transform 0.2s;
  position: absolute;
}
 .scroll-left{
  left: 0;
 }
 .scroll-right{
  right: 0;
 }

.metric-card {
  background: white;
  border-radius: 12px;
  min-width: 214px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.mertic-top-container{
  background-color: #E5F2F3;
  padding: 6.5px 15px;
  height: 49px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mertic-bottom-container{
  background-color: #ffffff;
  padding: 6.5px 15px;
  height: 49px;
  display: flex;
  justify-content: center;
  align-items: center;

}


.metric-title{
  color: #111111;
  font-size: 16px;
  line-height: 19px;
 font-weight: 500;
 font-family: 'AvenirBook';
}

.metric-subtitle {
  color: #111111;
  font-size: 18px;
  line-height: 21px;
 font-weight: 700;
 font-family: 'AvenirBook';
}




