.sum-head{
    text-align: left;
    font: normal normal bold 20px/12px AvenirBook;
    letter-spacing: 0px;
    margin:55px 0 30px;
}
.sum-sub-heading ,.resume-head{
    color: #199094!important;
    font-size: 18px;
}
.sum-box{
    background: #F5FBFD 0% 0% no-repeat padding-box;
    border: 2px solid #199094;
    padding:50px 30px 70px;
    border-radius: 7px;
    margin:40px 0;
}
.editable-ps-textarea{
font: normal normal normal 16px/32px AvenirBook !important;
letter-spacing: 0px;
color: #32AAB0;
padding:0 !important;
}
.inline-inp{
    text-align: left;
font: normal normal normal 16px/22px AvenirBook;
letter-spacing: 0px;
color: #32AAB0;
width:110px;
padding:0 !important;
/* margin-top: -7px; */
}
.inline-span{
    text-align: left;
font: normal normal normal 16px/32px AvenirBook;
letter-spacing: 0px;
color: #32AAB0;
width:110px;
padding:0 !important;
/* margin-top: -7px; */
}
.inline-inp input{
    text-align: left;
font: normal normal normal 16px/22px AvenirBook;
letter-spacing: 0px;
color: #32AAB0;
width:inherit;
padding:0 0 0 5px!important;
/* margin-top: -7px; */
}
.inline-form_item{
    display:inline-block;
    margin:-4px 0 15px;
}
.inline-text{
    text-align: left;
font: normal normal normal 16px/32px AvenirBook;
letter-spacing: 0px;
color: #414141;
/* padding: 0 10px; */
}
.popover-saved-content{
    text-align: left;
font: normal normal normal 12px/32px AvenirBook;
letter-spacing: 0px;
color: #414141;
}