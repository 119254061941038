/* @import 'antd/dist/antd.css'; */
#components-form-demo-normal-login .login-form {
    max-width: 50px;
  }
  
#components-form-demo-normal-login .login-form-forgot {
    float: right;
  }

#components-form-demo-normal-login .ant-col-rtl .login-form-forgot {
    float: left;
  }

#components-form-demo-normal-login .login-form-button {
    width: 30%;
  }

.test1{
    font-size: xx-large;
    color: blue;
}
#normal_login .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector{
  height: 36px;
}
#normal_login .ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input{
  height: 34px;
}

/* input:focus {
  border-color: #1eff00 !important;
  border-right-width: .5px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2) !important;
} */

/* .ant-form-item .input:active{
  border-color: red;

}

input{
  border:1px solid red !important;
} */

input :focus-within{
  border:1px solid red !important;
}
.resend{
  display: contents !important;
  color: #199094 !important;
  font-size: 14px !important;
  font-family: 'AvenirBook';
}
.resend:hover{
  display: contents;
  color: #199094;
}