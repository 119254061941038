/* .ant-steps-item-process .ant-steps-item-icon {
  background: #ffca3e !important;
}
.ant-steps-item-process .ant-steps-item-icon {
  border-color: #ffca3e !important;
} */

.ant-steps-item-active .ant-steps-item-icon {
  background: #199094 !important;
}
.ant-steps-item-active .ant-steps-item-icon {
  border-color: #199094 !important;
}

/* .anticon {
    display: inline-block;
    color: white;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
} */
.ant-steps-item-container:hover{
  color: #199094 !important
}
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #199094 !important;
  border-color: #199094 !important;
}

.ant-steps-item-icon .ant-steps-icon {
  position: relative !important;
  top: -1px !important;
  color: #199094;
  line-height: 1;
}

.ant-steps-item-icon {
  width: 32px !important;
  height: 32px !important;
  margin: 0 8px 0 0;
  font-size: 18px !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 32px;
  text-align: center !important;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  -webkit-transition: background-color 0.3s, border-color 0.3s;
  transition: background-color 0.3s, border-color 0.3s;
}

.ant-steps-item-title {
  position: relative;
  display: inline-block;
  padding-right: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 18px !important;
  line-height: 32px;
}

.ant-menu-inline-collapsed {
  width: 94px;
  display: none;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: white !important;
}
.step {
  background-color: red;
  border-color: red;
  background: red;
}

#cont {
  position: relative;
  display: block;
}

#cont::before {
  content: "Start here";
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 99;
  font-size: 14px;
}

#text {
  text-indent: 100px;
  min-width: 300px;
  min-height: 150px;
  font-size: 14px;
  padding: 5px;
}

.disabled-div > p{
  font-weight: bold;
}
.disabled-div{
  opacity:1;
  margin: 110px 0;
}

.disabled-div:focus-within, :focus{
  opacity:1;
}

.disabled-div:focus-within, :hover{
  opacity:1;
}

.experiences .ant-form-item {
  margin-bottom: 0px !important;
}

.cont-5 {
  position: relative;
  display: block;
}
.cont-5::before {
  content: "The goal of this experience was to: ";
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 99;
  font-size: 16px;
}
.text-5 {
  text-indent: 247px;
  min-width: 300px;
  min-height: 150px;
  font-size: 14px;
  padding: 5px;
}

.cont-6 {
  position: relative;
  display: block;
}
.cont-6::before {
  content: "I loved: ";
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 99;
  font-size: 16px;
}
.text-6 {
  text-indent: 55px;
  min-width: 300px;
  min-height: 150px;
  font-size: 14px;
  padding: 5px;
}

.cont-23 {
  position: relative;
  display: block;
}
.cont-23::before {
  content: "The theme would be: ";
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 99;
  font-size: 16px;
}
.text-23{
  text-indent: 155px;
  min-width: 300px;
  min-height: 150px;
  font-size: 14px;
  padding: 5px;
}



.cont-24 {
  position: relative;
  display: block;
}
.cont-24::before {
  content: "The thread is: ";
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 99;
  font-size: 16px;
}
.text-24{
  text-indent: 103px;
  min-width: 300px;
  min-height: 150px;
  font-size: 14px;
  padding: 5px;
}

/* .cont-27 {
  position: relative;
  display: block;
}
.cont-27::before {
  content: "I was influenced by: ";
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 99;
  font-size: 14px;
}
.text-27{
  text-indent: 138px;
  min-width: 300px;
  min-height: 150px;
  font-size: 14px;
  padding: 5px;
} */

.cont-28 {
  position: relative;
  display: block;
}
.cont-28::before {
  content: "I want to: ";
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 99;
  font-size: 14px;
}
.text-28{
  text-indent: 72px;
  min-width: 300px;
  min-height: 150px;
  font-size: 16px;
  padding: 5px;
}

.freeform-box{
  margin-bottom: 15px !important;
}

.cont-53 {
  position: relative;
  display: block;
}
.cont-53::before {
  content: "The challenge I faced that I want to help solve for others is:";
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 99;
  font-size: 15px;
}
.text-53{
  text-indent: 415px;
  min-width: 300px;
  min-height: 150px;
  font-size: 14px;
  padding: 5px;
}

.cont-54 {
  position: relative;
  display: block;
}
.cont-54::before {
  content: "The challenge I've seen others face that I want to help people solve is:";
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 99;
  font-size: 15px;
}
.text-54{
  text-indent: 491px;
  min-width: 300px;
  min-height: 150px;
  font-size: 14px;
  padding: 5px;
}

.cont-51 {
  position: relative;
  display: block;
}
.cont-51::before {
  content: "I'd like to write about:";
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 99;
  font-size: 16px;
}
.text-51{
  text-indent: 157px;
  min-width: 300px;
  min-height: 150px;
  font-size: 14px;
  padding: 5px;
}

.cont-57{
  position: relative;
  display: block;
}
.cont-57::before {
  content: "I think about:";
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 99;
  font-size: 16px;
}
.text-57{
  text-indent: 99px;
  min-width: 300px;
  min-height: 150px;
  font-size: 14px;
  padding: 5px;
}

.cont-9{
  position: relative;
  display: block;
  /* margin-bottom: 15px; */
}
.cont-9::before {
  content: "I would:";
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 99;
  font-size: 16px;
}
.text-9{
  text-indent: 59px;
  min-width: 300px;
  min-height: 150px;
  font-size: 14px;
  padding: 5px;
}

.cont-14{
  position: relative;
  display: block;
  /* margin-bottom: 15px; */
}
.cont-14::before {
  content: "I was:";
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 99;
  font-size: 16px;
}
.text-14{
  text-indent: 43px;
  min-width: 300px;
  min-height: 150px;
  font-size: 14px;
  padding: 5px;
}

.cont-15{
  position: relative;
  display: block;
  /* margin-bottom: 15px; */
}
.cont-15::before {
  content: "I would want to improve:";
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 99;
  font-size: 16px;
}
.text-15{
  text-indent: 179px;
  min-width: 300px;
  min-height: 150px;
  font-size: 14px;
  padding: 5px;
}

.cont-16{
  position: relative;
  display: block;
  /* margin-bottom: 15px; */
}
.cont-16::before {
  content: "I would:";
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 99;
  font-size: 16px;
}
.text-16{
  text-indent: 62px;
  min-width: 300px;
  min-height: 150px;
  font-size: 14px;
  padding: 5px;
}

.cont-17{
  position: relative;
  display: block;
  /* margin-bottom: 15px; */
}
.cont-17::before {
  content: "My side hustle is:";
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 99;
  font-size: 16px;
}
.text-17{
  text-indent: 125px;
  min-width: 300px;
  min-height: 150px;
  font-size: 14px;
  padding: 5px;
}

.cont-19{
  position: relative;
  display: block;
  /* margin-bottom: 15px; */
}
.cont-19::before {
  content: "I was:";
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 99;
  font-size: 16px;
}
.text-19{
  text-indent: 45px;
  min-width: 300px;
  min-height: 150px;
  font-size: 14px;
  padding: 5px;
}

.cont-22{
  position: relative;
  display: block;
  /* margin-bottom: 15px; */
}
.cont-22::before {
  content: "I admire the career of:";
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 99;
  font-size: 16px;
}
.text-22{
  text-indent: 161px;
  min-width: 300px;
  min-height: 150px;
  font-size: 14px;
  padding: 5px;
}

.cont-31{
  position: relative;
  display: block;
  /* margin-bottom: 15px; */
}
.cont-31::before {
  content: "I loved:";
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 99;
  font-size: 16px;
}
.text-31{
  text-indent: 55px;
  min-width: 300px;
  min-height: 150px;
  font-size: 14px;
  padding: 5px;
}

.cont-32{
  position: relative;
  display: block;
  /* margin-bottom: 15px; */
}
.cont-32::before {
  content: "I disliked:";
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 99;
  font-size: 16px;
}
.text-32{
  text-indent: 71px;
  min-width: 300px;
  min-height: 150px;
  font-size: 14px;
  padding: 5px;
}

.cont-33{
  position: relative;
  display: block;
  /* margin-bottom: 15px; */
}
.cont-33::before {
  content: "What's missing is:";
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 99;
  font-size: 16px;
}
.text-33{
  text-indent: 133px;
  min-width: 300px;
  min-height: 150px;
  font-size: 14px;
  padding: 5px;
}

.cont-35{
  position: relative;
  display: block;
  /* margin-bottom: 15px; */
}
.cont-35::before {
  content: "We explore:";
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 99;
  font-size: 16px;
}
.text-35{
  text-indent: 89px;
  min-width: 300px;
  min-height: 150px;
  font-size: 14px;
  padding: 5px;
}

.cont-36{
  position: relative;
  display: block;
  /* margin-bottom: 15px; */
}
.cont-36::before {
  content: "I think about:";
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 99;
  font-size: 16px;
}
.text-36{
  text-indent: 99px;
  min-width: 300px;
  min-height: 150px;
  font-size: 14px;
  padding: 5px;
}

.cont-37{
  position: relative;
  display: block;
  /* margin-bottom: 15px; */
}
.cont-37::before {
  content: "I help others with:";
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 99;
  font-size: 16px;
}
.text-37{
  text-indent: 133px;
  min-width: 300px;
  min-height: 150px;
  font-size: 14px;
  padding: 5px;
}

.cont-43{
  position: relative;
  display: block;
  /* margin-bottom: 15px; */
}
.cont-43::before {
  content: "I click on articles about:";
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 99;
  font-size: 16px;
}
.text-43{
  text-indent: 169px;
  min-width: 300px;
  min-height: 150px;
  font-size: 14px;
  padding: 5px;
}

.cont-44{
  position: relative;
  display: block;
  /* margin-bottom: 15px; */
}
.cont-44::before {
  content: "I could talk endlessly about:";
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 99;
  font-size: 16px;
}
.text-44{
  text-indent: 199px;
  min-width: 300px;
  min-height: 150px;
  font-size: 14px;
  padding: 5px;
}

.cont-45{
  position: relative;
  display: block;
  /* margin-bottom: 15px; */
}
.cont-45::before {
  content: "I get excited when I talk about:";
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 99;
  font-size: 16px;
}
.text-45{
  text-indent: 221px;
  min-width: 300px;
  min-height: 150px;
  font-size: 14px;
  padding: 5px;
}
/* 
.cont-46{
  position: relative;
  display: block;
}
.cont-46::before {
  content: "I love learning/talking about:";
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 99;
  font-size: 14px;
}
.text-46{
  text-indent: 199px;
  min-width: 300px;
  min-height: 150px;
  font-size: 14px;
  padding: 5px;
} */

.cont-47{
  position: relative;
  display: block;
  /* margin-bottom: 15px; */
}
.cont-47::before {
  content: "The message was:";
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 99;
  font-size: 16px;
}
.text-47{
  text-indent: 135px;
  min-width: 300px;
  min-height: 150px;
  font-size: 14px;
  padding: 5px;
}

.cont-48{
  position: relative;
  display: block;
  /* margin-bottom: 15px; */
}
.cont-48::before {
  content: "I see myself helping:";
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 99;
  font-size: 16px;
}
.text-48{
  text-indent: 155px;
  min-width: 300px;
  min-height: 150px;
  font-size: 14px;
  padding: 5px;
}


/* .cont-55{
  position: relative;
  display: block;
}
.cont-55::before {
  content: "I could talk endlessly about: ";
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 99;
  font-size: 14px;
}
.text-55{
  text-indent: 192px;
  min-width: 300px;
  min-height: 150px;
  font-size: 14px;
  padding: 5px;
} */

.cont-58{
  position: relative;
  display: block;
  /* margin-bottom: 15px; */
}
.cont-58::before {
  content: "My worldview is that:";
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 99;
  font-size: 16px;
}
.text-58{
  text-indent: 154px;
  min-width: 300px;
  min-height: 150px;
  font-size: 14px;
  padding: 5px;
}
#experiences .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector{
  height: 60px;
}
/* #experiences .ant-select-multiple.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector{
  height: 60px;
} */