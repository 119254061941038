.hide-research{
    border: 0 !important;
    outline: 0 !important;
    background: transparent !important;
    margin-bottom: 20px;
    font: normal normal bold 14px/44px AvenirBook;
    letter-spacing: 0px;
    color: #199094;
    margin-left: -30px;
}
.notes_div{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.5px solid #199094;
    border-radius: 5px;
    opacity: 1;
    width: 100%;
}
.ref_head{
    text-align: left;
    font: normal normal 600 20px/12px AvenirBook;
    letter-spacing: 0px;
    color: #414141;
    padding:10px 0;
    width:100%;
}
.hide_arrow{
    font-size: 24px;
    padding-right: 20px;
}
.cn-low-card{
    padding: 24px !important;
}