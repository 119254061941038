.email-head{
    text-align: left;
    font: normal normal 600 18px/22px AvenirBook;
    letter-spacing: 0px;
    color: #199094;
    opacity: 1;
    margin-top:60px;
    margin-bottom:10px;
}
.email-subhead{
    text-align: left;
    font: normal normal 500 18px/22px AvenirBook;
    letter-spacing: 0px;
    color: #199094;
    opacity: 1;
    margin-bottom:30px;
}
.e-card-head, .e-card-head .ant-input{
    text-align: left;
    font: normal normal 600 17px/22px AvenirBook;
    letter-spacing: 0px;
    color: #111111;
    margin-bottom:15px;
    resize: none;
    padding:0;
}
.e-card-subhead, .e-card-subhead .ant-input {
    text-align: left;
    font: normal normal 500 16px/22px AvenirBook;
    letter-spacing: 0px;
    color: #587B7B;
    margin-bottom:20px;
    resize: none;
    padding:0;
}
.e-card-subhead{
    color: #111111;
}
.e-card{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.5px solid #199094;
    border-radius: 5px;
    padding-bottom:20px;
    margin-bottom:100px;
}
.e-row{
    height:60px;
    text-align:left;
    border-bottom: 0.5px solid #199094;
}
.to{
    text-align: left;
    font: normal normal bold 16px/14px AvenirBook;
    letter-spacing: 0px;
    color: #111111;
    padding:20px;
}
.e-date{
    text-align: left;
    font: normal normal normal 16px/22px AvenirBook;
    letter-spacing: 0px;
    color: #606368;
}
.e-draft{
    padding:20px;
}
.submit-e{
    width: 225px;
    height: 42px;
    background: #49CDE1 0% 0% no-repeat padding-box !important;
    border-radius: 3px;
    opacity: 1;
    border:0 !important;
    outline:0 !important;
    font: normal normal bold 16px/44px AvenirBook;

}
.sub-c{
    resize: none;
    padding: 16px 20px !important;
    color: #111111 !important;
}
.person{
    font: normal normal 500 16px/22px AvenirBook;
    letter-spacing: 0px;
    color: #111111 !important;
    resize: none !important;
    padding: 16px 20px !important;
}
.intro-draft{
    font: normal normal 500 16px/22px AvenirBook;
    letter-spacing: 0px;
    color: #111111;
    padding:0;
}