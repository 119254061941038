
.select-caller {
    height:43px;
    width:219px;
    margin-right:30px !important;
}
.select-caller .ant-select-selector{
    height:43px !important;
    vertical-align:middle;
    width:219px !important;
    padding-top: 5px !important;
}
.caller-tags-div{
    margin:0 7px;
}
.tag-head{
    text-align: left;
    font: normal normal normal 10px/22px AvenirBook;
    letter-spacing: 0px;
    color: #199094;
}
.role-tag{
    background: #8375E8 0% 0% no-repeat padding-box !important;
    border-radius: 15px !important;
    color:#FFFFFF !important;
    padding:5px 10px !important;
    font: normal normal 600 15px AvenirBook !important;
    max-width: 100% !important;
    /* margin:0 5px; */
}
.ind-tag{
    background: #F59D6B 0% 0% no-repeat padding-box !important;
    border-radius: 15px !important;
    color:#FFFFFF !important;
    padding:5px 10px !important;
    font: normal normal 600 15px AvenirBook !important;
    /* margin:0 5px; */
}
.notes-head{
    text-align: left;
    font: normal normal bold 17px/44px AvenirBook;
    letter-spacing: 0px;
    color: #111111;
    margin-bottom :20px;;
}
.notes-card{
    width: 83% !important;
    min-height: 300px !important;
    border-top: 10px solid #CCE6E7 !important;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border-left: 0px solid #CCE6E7 !important;
    border-right: 0px solid #CCE6E7 !important;
    border-bottom: 0px solid #CCE6E7 !important;
    margin-bottom:30px !important;
    box-shadow: 0px 3px 16px 0px #1313131A !important;

}
/* 
.notes-card:hover{
    border-top: 10px solid #FFCA3E;
    box-shadow: 2px 5px 8px #00000029;
} */

.notes-card:focus-within{
    border-top: 10px solid #ffca3e !important;
    background: #ffffff 0% 0% no-repeat padding-box !important;
}

.notes-card-focus-within:focus-within{
    border-top: 10px solid #ffca3e !important;
    background: #ffffff 0% 0% no-repeat padding-box !important;
}
.notes-card-focus-within{
    width: 83% !important;
    min-height: 300px !important;
    border-left: 0px solid #CCE6E7 !important;
    border-right: 0px solid #CCE6E7 !important;
    border-bottom: 0px solid #CCE6E7 !important;
    margin-bottom:30px !important;
    border-top: 10px solid #199094 !important;
    background: #F5FFFE 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 16px 0px #1313131A !important;

}
.notes-card-heading{
    text-align: left;
    font: normal normal normal 17px/21.86px AvenirBook;
    letter-spacing: 0px;
    color: #111111;
}
.notes-card-subhead{
    text-align: left;
    font: normal normal normal 16px/20px AvenirBook;
    letter-spacing: -0.36px;
    color: #199094;
}
.ques_box{
    height:280px;
    margin-bottom:50px;
    width:80%;
}
.scrollable-q{
    height:230px;
    overflow-y: scroll;
}
.scrollable-q::-webkit-scrollbar{
    display: none;
}
.q-box-head{
    text-align: left;
    font: normal normal normal 17px/21.86px AvenirBook ;
    letter-spacing: 0px;
    color: #111111;
    border-bottom: 2px solid #199094;
    padding-bottom: 10px;
}
.add_q_btn{
    float: left !important;
    width: 20px !important;
    height: 20px !important;
    font-size: 19px !important;
    margin-top: 1em !important;
    color:#CCE6E7 !important;
    font-weight:bold !important;
}
.add_q_btn:hover{
    color:#199094 !important;
}
.ques-input-edit{
    font: normal normal normal 16px/22px AvenirBook;
    letter-spacing: 0px;
    margin:0 0px 0 25px;
    padding: 0;
}
.ques-input-edit input {
    color: #404040;
}
.ques-checkbox-div{
    display:flex;
    padding:12px 0;
    position: relative;
}
.custom-cb .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #199094 !important;
}
.custom-cb .ant-checkbox-wrapper .ant-checkbox-inner {
    border-color: #199094 !important;
}
/* .ques-checkbox{
    width:20px;
    height:20px;
    border-radius:50%;
} */
/* .ques-checkbox:checked{
    background-color: #CCE6E7;
    color:#FFFFFF
} */

.ques-editable{
    display: none;
    cursor: pointer;
    color:#49CDE1;
}
.custom-textarea{
    font: normal normal normal 16px/22px AvenirBook;
    letter-spacing: 0px;
    padding: 10px !important;
    margin-left: 5% !important;
    width: 95%;

}
.ant-col-1 .custom-cb .ant-checkbox-wrapper-checked .ant-checkbox-checked input:checked + .ant-col-23 .custom-textarea{
    text-decoration: line-through;
      text-decoration-color: #CCE6E7;
      color:#CCE6E7;
      pointer-events: none; 
}
.custom-textarea:focus-within, .custom-textarea:hover, .custom-textarea:focus{
    border-bottom:solid 1px #199094 !important;
}
.custom-textarea-checked{
    font: normal normal normal 16px/22px AvenirBook;
    letter-spacing: 0px;
    padding:10px 10px 10px 20px;
    text-decoration: line-through;
    text-decoration-color: #CCE6E7;
    color:#CCE6E7;
    cursor: default;
    pointer-events: none;

}
.custom-cb .ant-checkbox-wrapper-checked ~ .custom-textarea {
    text-decoration: line-through;
      text-decoration-color: #CCE6E7;
      color:#CCE6E7;
      pointer-events: none;
  }
.ques-editable:focus  .ques-input-edit{
    border-bottom:solid 1px #49CDE1;
}
.ques-input-edit:hover  .ques-editable{
    display:block;
}
.ques-input-edit:focus-within .ques-editable{
    display:none;
}
.ques-input-edit:focus-within{
    border-bottom:solid 1px #49CDE1;
}
.ques-checkbox-container {
    position: relative;
  }
.ques-checkbox {
    position: absolute;
    top: 10px;
    opacity: 0;
    cursor: pointer;
    height: 20px;
    width: 20px;
    z-index: 10;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 13px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border-radius:50%;
    border:1px solid #AEB7C2; 
    cursor: pointer;
  }
  
  /* On mouse-over, add a grey background color */
  /*.container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  */
  /* When the checkbox is checked, add a blue background */
  .ques-checkbox:checked ~ .checkmark {
    background-color: #CCE6E7;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
 .ques-checkbox:checked + .checkmark:after {
    display: block;
  }
  .ques-checkbox:checked ~ .ques-input-edit input {
    text-decoration: line-through;
    text-decoration-color: #CCE6E7;
    color:#CCE6E7;
    cursor: default;
    pointer-events: none;
}
.ques-checkbox:checked ~ .ques-input-edit{
    border:0 !important;
    pointer-events: none;
}
.ques-checkbox:checked ~ .ques-input-edit:hover .ques-editable{
    display:none;
}
form#Cl-form .ant-input{
    font-size: 17px !important;
}
  
  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 7px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  