ul.tasks-list {
  list-style: none;
  padding-left: 0;
  max-height: 60vh;
  overflow-y: auto;
  margin-top: 26px;
  margin-bottom: 20px;
}

/* .tasks-list li:first-child {
    margin-top: 42px;
} */

.tasks-list li {
  background: #F6FAFB;
  color: #606368;
  padding: 12px;
  font-size: 15px;
  /* border-bottom: solid #199094 3px; */
  /* border-bottom: solid #ffca3e 3px; */
  border-radius: 8px;
  border: 1px solid #E8F4F7;
}
.tasks-list li .drag-hover {
  opacity: 0;
  color: #64d0d3;
}
.tasks-list li:hover .drag-hover {
  opacity: 1;
  color: #64d0d3;
}

/* .tasks-list li.ongoing {
  border-bottom-color: #ffca3e;
} */

.tasks-list li.weekly-reflection {
  border-bottom-color: #cce6e7;
}

/* .tasks-list li.botom-purple {
  border-bottom-color: #cce6e7;
} */

.tasks-list li.call {
  border-bottom-color: #cce6e7;
}

.tasks-list li:not(:first-child) {
  margin-top: 12px;
}

.tasks-list li .spinner {
  height: 23px;
  /* margin-right: 10px; */
  width: 23px;
}
.tasks-list li .spinner.new-task {
  height: 23px;
  margin-right: 10px;
  width: 23px;
}

.tasks-list li .due-date {
  float: right;
  font-weight: 400;
}
.tasks-list li .phone {
  height: 23px;
  margin-right: 10px;
  width: 23px;
}
.div-my-tasks-title{
  display: flex;
  justify-content: start;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
}
.dashboard-my-tasks-tag-ongoing{
  background: #FDECC8;
  color: #402C1B;
  padding: 3.5px 7px;
  gap: 7px;
  border-radius: 80px;
  font-size: 15px;
  font-weight: 800;
  border: 1px solid #E39203;
  background: #FFF8ED;
  color: #E39203;
}
.dashboard-my-tasks-tag-ongoing.status-badge {
  display: inline-flex;
  align-items: center;
  font-weight: 700;
  gap: 7px;
}
.dashboard-my-tasks-tag-ongoing.status-badge .status-dot {
  width: 8px;
  height: 8px;
  /* background-color: #E39203;
  border-radius: 50%; */
  width: 12px;
  height: 12px;
}
.dashboard-my-tasks-tag-notStarted{
  background: #D3E5EF;
  color: #193347;
  padding: 3.5px 7px;
  gap: 7px;
  border-radius: 80px;
  font-size: 15px;
  font-weight: 800;
  border: 1px solid #F173F4;
  background: #FFF6FF;
  color: #F173F4;
}
.dashboard-my-tasks-tag-notStarted.status-badge {
  display: inline-flex;
  align-items: center;
  font-weight: 700;
  gap: 7px;
}
.dashboard-my-tasks-tag-notStarted.status-badge .status-dot {
  width: 8px;
  height: 8px;
  /* background-color: #F173F4;
  border-radius: 50%; */
  width: 12px;
  height: 12px;
}
.my-tasks-add-new-buttom{
  font-size: 12px;
  font-weight: 400;
  line-height: 16.39px;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 4px;
  width: 112px;
  height: 32px;
  padding: 8px;
  box-shadow: 0px 2px 0px 0px #0000000B;
}