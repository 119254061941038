.tail-datetime-calendar-wrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  margin: 24px;
  border-radius: 13px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tail-datetime-calendar {
  position: relative;
  box-shadow: none;
  -webkit-box-shadow: none;
  border-radius: none;
  -webkit-border-radius: none;
  width: 280px;
}

.tail-datetime-calendar .calendar-navi {
  background-color: #fff;
  margin-bottom: 10px;
}

.tail-datetime-calendar .calendar-navi span {
  color: #585858;
  text-transform: uppercase;
  text-shadow: none;
  font-size: 20px;
  line-height: 22px;
  font-weight: bold;
  font-family: AvenirBook;
}

.tail-datetime-calendar .calendar-navi .calendar-label {
  cursor: default;
}

.tail-datetime-calendar .calendar-navi span.button-prev {
  background: transparent url("https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/arrow_left.svg") 0% 0% no-repeat
    padding-box;
  width: 9px;
  height: 15px;
  padding: 10px;
}

.tail-datetime-calendar .calendar-navi span.button-next {
  background: transparent url("https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/arrow_right.svg") 0% 0% no-repeat
    padding-box;
  width: 9px;
  height: 15px;
  padding: 10px;
}

.tail-datetime-calendar .calendar-date table thead tr > *,
.tail-datetime-calendar .calendar-date table tbody tr > * {
  color: #414141;
  font-size: 12px;
  font-family: AvenirBook;
  font-weight: bold;
  line-height: 44px;
  background-color: #fff;
}

.tail-datetime-calendar .calendar-date table tbody tr > .empty,
.tail-datetime-calendar .calendar-date table tbody tr > .disable {
  color: transparent;
  background-color: transparent;
}

.tail-datetime-calendar .calendar-date table tbody tr > ::after {
  top: 5px;
  left: 5px;
  width: 30px;
  height: 30px;
}

.tail-datetime-calendar .calendar-navi span:hover {
  background-color: transparent;
}

.tail-datetime-calendar .calendar-date table tbody tr > .today {
  color: #fff;
}

.tail-datetime-calendar .calendar-date table tbody tr > .today::after {
  border-color: #199094;
  background-color: #199094;
}

.calendar-day .events {
  position: absolute;
  bottom: 10px;
  left: 15px;
  display: block;
  display: flex;
  justify-content: center;
  width: 10px;
}

.calendar-day .events > .event-dot {
  height: 4px;
  width: 4px;
  border-radius: 2px;
  display: block;
}

.personal.event-dot {
  background: #3da2ba;
}

.coach.event-dot {
  background: #f39380;
}

.tail-datetime-calendar .calendar-date table tbody tr > * > span.events {
  position: absolute;
}
.tail-datetime-calendar .calendar-date table thead tr > th,
.tail-datetime-calendar .calendar-date table tbody tr > * > span {
  font-size: 16px;
  line-height: 22px;
}

.tail-datetime-calendar .calendar-date table tbody tr > * {
    font-weight: normal;
}
