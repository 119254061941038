.rbc-calendar{
    margin: 0 !important;
}
.toolbar-label{
    display: inline-block;
    font: normal normal 600 20px/12px AvenirBook;
    letter-spacing: 0px;
    color: #111111;
    font-weight: bold;
    padding: 0 40px;
    vertical-align: middle;
    margin: 0;
}
.rbc-time-view {
    border: 0;
}
.rbc-header + .rbc-header {
    border-left: 0;
}
.rbc-label{
    max-width: 100px !important;
    width: 72.5px !important;
}
.rbc-time-slot .rbc-label{
    color: #AEB7C8;
}
.rbc-event{
    background-color: #3DA2BA;
    border-radius: 8px !important;
    border: 0 !important;
    /* width: inherit !important;
    min-width: 100% !important; */
}
.rbc-timeslot-group {
    min-height: 50px;
}
.rbc-today {
    background-color: transparent;
}
.rbc-today a{
    background-color: #199094; 

}
.rbc-today a span{
     color: #ffffff !important;

}
/* .rbc-today a ::before {
    content: '';
    position: absolute;
     top: 0; 
    background-color: #32AAB0;
    height: 35px;
    width: 35px;
    z-index: -10000000;
    border-radius: 50%;
    display: block;
} */
.rbc-header{
    min-height: 60px;
    white-space: break-spaces;
    padding: 0;
}
.rbc-header a{
    display: block;
    height: inherit;
    color: #AEB7C2;
    pointer-events: none;

}
.rbc-header a span{
    display: block;
    padding: 5px 40%;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    color: #AEB7C2;
}
.rbc-day-slot .rbc-event-content {
    font-weight: bold;
}
.rbc-time-view .rbc-allday-cell {
    display: none;
}
.rbc-time-header-gutter{
    border: 0;
}

.rbc-time-header-content {
    border-left: 0;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor .rbc-addons-dnd-resize-ns-icon {
    border-top: 7px double;
    width: 20px;
}
.edit-task-modal .ant-modal-body{
    padding:45px 70px 10px 40px;
}
.edit-task-items{
    text-align: left;
font: normal normal normal 16px/22px AvenirBook;
letter-spacing: 0px;
color: #414141;
}
.add-to-calendar{
    text-align: left;
font: normal normal normal 14px/22px AvenirBook;
letter-spacing: 0px;
color: #606368;
}
.google-calendar{
    text-align: left;
font: normal normal medium 14px/22px AvenirBook;
letter-spacing: 0px;
color: #199094;
display: inline-block;
}
.delete-task-btn{
    font: normal normal bold 16px AvenirBook;
letter-spacing: 0px;
color: #199094 !important;
border: 0 !important;
outline: 0 !important;
width: 100px !important;
height: 42px !important;
}
.save-task-btn{
    font: normal normal bold 16px AvenirBook;
letter-spacing: 0px;
color:#ffffff;
width: 112px;
height: 42px;
border: 0 !important;
outline: 0 !important;
background: #199094 0% 0% no-repeat padding-box;
border-radius: 8px;
}
.react-add-to-calendar__button{
    display: none !important;
}
.react-add-to-calendar__dropdown{
    display: block !important;
}
.rbc-current-time-indicator{
    background-color: #199094;
}