.networking-nav{
    padding-top:40px;
}

.contacts-head{
    text-align: left;
    font: normal normal 600 14px/22px AvenirBook;
    letter-spacing: 0px;
    color: #199094;
    padding: 60px 0 40px;
}

.sum-sub-heading{
    color: #199094!important;
}