.draggable-item {
  background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255);
  border: 1px solid rgb(204, 214, 225);
  border-radius: 8px;
  text-align: center;
  padding: 20px 15px;
  margin: 10px 0px;
  cursor: all-scroll;
}

.draggable-item:hover .svg-visible {
  display: initial;
}

.draggable-item:active .svg-visible {
  display: initial;
}

.svg-visible {
  /* visibility:hidden; */
  display: none;
}

.row-dragging {
  background-color: #ffffff;
}

.row-dragging td {
  /* padding: 1px; */
}

.row-dragging .drag-visible {
  visibility: visible;
}

.drag {
  padding: 0 0 0 20px;
}

.delete {
  padding: 0 20px 0 0;
}

.text {
  width: 100%;
  text-align: center;
  padding: 17px 0 23px 0;
}

.list-item {
  background-color: #ffffff;
  height: 40px;
  margin-bottom: 10px;
  list-style: none;
  outline: none;
  z-index: 9999;
}
.list-item .title span.anticon.anticon-edit.editable svg{
  width: 18px;
  height: 18px;
}

ul.numbering {
  list-style: none;
  float: left;
  width: 40px;
  padding-left: 0;
}

ul.numbering li {
  background: #199094 0% 0% no-repeat padding-box;
  border: 1px solid #199094;
  border-radius: 8px;
  font-size: 17px;
  color: #ffffff;
  width: 40px;
  height: 40px;
  text-align: center;
  display: block;
  padding-top: 7px;
  margin-bottom: 10px;
}

.list-item .title {
  border: 1px solid #ccd6e1;
  border-radius: 8px;
  position: relative;
  height: 40px;
  padding-top: 6px;
  text-align: center;
}

.typo {
  margin: 0;
}

.drag-hover {
  opacity: 0;
}

/* .drag-hover:hover{
  opacity: 1;
} */

.list-item:hover .drag-hover {
  opacity: 1;
}

.list-item:active .drag-hover {
  opacity: 1;
}

.editable {
  opacity: 0;
  cursor: pointer;
}

.list-item:hover .editable {
  opacity: 1;
  color: #32aab0;
}

.list-item:active .editable {
  opacity: 1;
  color: #32aab0;
}

.list-item:focus-within .input-edit {
  opacity: 1;
  border-bottom: 1px solid #6dc6ca;
}

.list {
  list-style: none;
  float: right;
  padding-left: 0;
  width: calc(100% - 62px);
}

.add-new {
  border-radius: 8px !important;
  background: #f9fafc !important;
  height: 30px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  width: 100%;
  border: 2px dashed #CCD6E1 !important;
  color: #199094 !important;
}

.clearfix {
  clear: both;
}

.my-target-role-industry-add-new-buttom{
  font-size: 16px;
  font-weight: 400;
  line-height: 16.39px;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 4px;
  /* width: 112px; */
  height: 40px;
  padding: 8px;
  box-shadow: 0px 2px 0px 0px #0000000B;
}
/* .ant-popover-inner-content:has(> .ti-dropdown){
  padding: 24px !important;
} */

/* .draggable-item {
  background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255);
  border: 1px solid rgb(204, 214, 225);
  border-radius: 8px;
  text-align: center;
  padding: 20px 15px;
  margin: 10px 0px;
  cursor: all-scroll;
}
 .draggable-item:hover .svg-visible {
  display: initial;
}
 .draggable-item:active .svg-visible {
  display: initial;
}
 .svg-visible {
  display: none;
}
 .row-dragging {
  background-color: #ffffff;
}
.row-dragging .drag-visible {
  visibility: visible;
}
.drag {
  padding: 0 0 0 20px;
}
.delete {
  padding: 0 20px 0 0;
}
.text {
  width: 100%;
  text-align: center;
  padding: 17px 0 23px 0;
}
.list-item {
  color: #111111;
  font-family: 'AvenirBook';
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
  padding: 0px;
  margin: 0px;
} 
.index-numbering{
  color: #111111;
  font-family: 'AvenirBook';
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
} 
ul.numbering {
  list-style: none;
  float: left;
  width: 32px;
  padding-left: 0;
}
ul.numbering li {
  color: #111111;
  font-family: 'AvenirBook';
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  list-style: none;
}
.target-roles-subbuttons{
  background-color: #CCE6E7;
  color: #199094;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  width: 63px;
  border: none;
  border-radius: 4px;
  padding: 4px 8px 4px 8px;
  margin-top: 4px;
  font-family: 'AvenirBook';

}
.draghandle{
  display: flex;
  justify-content: center;
  align-items: center;
}
.list-item .title {
  position: relative;
  list-style: none;
  text-align: left;
  background-color: #F6FAFB;
  margin: 12px 0px;
  padding: 10px 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}
.row-container{
width: 100%;
  display: flex;
  align-items: center;
}
.list-item .title-new{
  margin: 10px 0px;
  background-color: #F6FAFB;
padding: 10px 8px;
border-radius: 8px;
}
.ant-popover-inner .ant-popover-inner-content .ti-dropdown .list .list-item{
  height: 40px;
  margin: 20px 0px;
}
.typo {
  margin: 0;
}
.drag-hover {
  opacity: 0;
  display: none;
}
.list-item:hover .drag-hover {
  opacity: 1;
  display: flex;
  padding: 0px 0px 0px 9px ;
}
.ant-input-affix-wrapper{
  padding: 4px 8px!important;
}
.list-item:active .drag-hover {
  opacity: 1;
}
.editable {
  opacity: 0;
  cursor: pointer;
}
.list-item:hover .editable {
  opacity: 1;
  color: #32aab0;
}
.list-item:active .editable {
  opacity: 1;
  color: #32aab0;
}
.list-item:focus-within .input-edit {
  opacity: 1;
  border-bottom: 1px solid #6dc6ca;
}
.list {
  list-style: none;
  float: right;
  padding-left: 0;
  width: 100%;
}
.add-new {
  border-radius: 8px !important;
  background: #f9fafc !important;
  height: 40px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  width: 100%;
  border: 2px dashed #CCD6E1 !important;
  color: #199094 !important;
}
.clearfix {
  clear: both;
} */