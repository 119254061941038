.research-head{
    text-align: left;
    font: normal normal 600 18px/22px AvenirBook;
    letter-spacing: 0px;
    color: #199094;
    opacity: 1;
    margin: 50px 0 30px;
}
.research_notes-head{
    text-align: left;
    font: normal normal bold 17px/44px AvenirBook;
    letter-spacing: 0px;
    color: #111111;
    margin-bottom :20px;;
}
.research_notes-card{
    width: 90% !important;
    min-height: 300px !important;
    border-top: 10px solid #cce6e7 !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 16px 0px #1313131A !important;
    border-left: 0px solid #cce6e7 !important;
    border-right: 0px solid #cce6e7 !important;
    border-bottom: 0px solid #cce6e7 !important;
    margin-bottom:30px;
}
/* 
.research_notes-card:hover{
    border-top: 10px solid #FFCA3E;
    box-shadow: 0px 3px 16px 0px #1313131A !important;
    
} */

.research_notes-card:focus-within{
    border-top: 10px solid #ffca3e !important;
    background: #ffffff 0% 0% no-repeat padding-box ;
}
.research_notes-card-focus-within{
    width: 90% !important;
    min-height: 300px !important;
    box-shadow: 0px 3px 16px 0px #1313131A !important;  
    border-left: 0px solid #cce6e7 !important;
    border-right: 0px solid #cce6e7 !important;
    border-bottom: 0px solid #cce6e7 !important;
    margin-bottom:30px !important;
    border-top: 10px solid #199094 !important;
    background: #F5FFFE 0% 0% no-repeat padding-box !important;
}
.research_notes-card-focus-within:focus-within{
    border-top: 10px solid #ffca3e !important;
    background: #ffffff 0% 0% no-repeat padding-box !important;
}
.research_notes-card-heading{
    text-align: left;
    font: bold normal normal 17px/22px AvenirBook;
    letter-spacing: 0px;
    color: #111111;
}
.research_notes-card-subhead{
    text-align: left;
    font: normal normal normal 16px/20px AvenirBook;
    letter-spacing: -0.36px;
    color: #199094;
}