.progress{
    height:8px;
}

.go-to-coach{
    width: 87% !important;
    background: #69C4C8;
    color: white !important;
    cursor: pointer;
    font-size: 24px;
    border-radius: 53px !important;
    margin-left: 6% !important;
}