.ip-head{
    margin: 70px 0 10px;
    text-align: left;
    font: normal normal 600 18px/22px AvenirBook;
    letter-spacing: 0px;
    color: #199094;
}
.ip-subhead{
    margin-bottom: 30px;
    text-align: left;
    font: normal normal normal 18px/22px AvenirBook;
    letter-spacing: 0px;
    color: #199094;
}
.ip-div{
    margin-bottom:80px;
    opacity:1
}
.ip-div:hover,.ip-div:focus-within{
    opacity: 1;
}
.ip-div-head{
    font: bold normal normal 17px/22px AvenirBook;
    letter-spacing: 0px;
    color: #414141;
}
.ip-div-head input{
    font: normal normal normal 16px/22px AvenirBook;
    letter-spacing: 0px;
    color: #414141;
}
.ip-div-head-editable:focus-within{
    border-bottom: solid 1px #49CDE1;
}
.ip-div-head-editable:hover  .ques-editable{
    display:block;
}
.ip-div-subhead{
    font: normal normal normal 16px/22px AvenirBook;
    letter-spacing: 0px;
    color: #587B7B;
    margin-bottom: 35px;
}
.ip-popover .ant-popover-inner{
    background: #EFFAFF 0% 0% no-repeat padding-box !important;
box-shadow: 0px 3px 6px #00000029 !important;
}
.popover-content{
    padding:20px;
}
.popover-h{
    font: normal normal 600 17px/22px AvenirBook;
    letter-spacing: 0px;
    color: #606368;
    text-transform: capitalize;
}
.popover-subh{
    text-align: left;
    font: normal normal normal 16px/22px AvenirBook;
    letter-spacing: 0px;
    color: #606368;
}
.inp-dot{
    display:flex;
    position: relative;
    margin-bottom: 30px;
}
.dot{
    position: absolute;
    top:4px;
    height:10px;
    width:10px;
    background: #199094 0% 0% no-repeat padding-box;
    border-radius:50%;
}
.ip-input{
    height: 44px;
    background: #F6F8FA 0% 0% no-repeat padding-box;
    border: 2px solid #CCD6E1;
    border-radius: 5px;
}
.dynamic-delete-button :hover{
    color:#32AAB0
}
