.contact-card{
  background:#199094 0% 0% no-repeat padding-box !important;
  border-radius: 12px !important;
  margin:2% 5% !important;
  padding:3% 4% !important;
  cursor:pointer !important;
  font-size: 16px !important;
  color: white;
}

.contact-cat{
  font-size:18px;
  font-weight:bold;
}

.outer-card{
  background-color:#ffffff !important;
  border:0px solid white !important;
  padding:10px 0 !important;
  border-radius: 12px !important;
}

.header-set{
  padding:5% 5%;
}

.add-icon{
  float: right !important;
  font-size: 50px !important;
  margin: -3% 1% !important;
  color: #199094 !important;
  cursor: pointer !important;
}

.add-icon:hover{
  float: right;
  font-size: 51px;
  margin: -3% 1%;
  color: #199094;
  cursor: pointer;
}

.info-circle svg[data-icon="info-circle"] path[fill="#1890ff"]{
  fill: #199094;
}
.info-circle svg[data-icon="info-circle"] path[fill="#e6f7ff"]{
  fill: #ffffff;
}
.info-circle{
  font-size:20px;
  padding-left: 8px;
  opacity:0.8;
  cursor:pointer;
  vertical-align: text-bottom !important;
  color: #E5F2F3;
}
.info-circle:hover{
  opacity:1;
  color: #199094;
}

.popover-content{
  font-size: 16px !important;
  letter-spacing: 0px !important;
  color: #707070 !important;
}

.view-all{
  color: #49CDE1;
  opacity: 0.6;
  font-size: 14px;
  margin: 0 2%;
  text-align: end;
}

.contact-modal-title{
  font-size: 24px;
  color: #111111;
  margin:0;
  padding:20px 0;
}

/* .contact-modal-content .ant-input, .contact-modal-content .ant-select-selector{
  font-size: 18px !important;
} */
.contact-modal-content{
  font-size: 19px;
  color: #111111;
  margin:0;
  padding:10px 0;
}
.close-btn-modal{
  position: relative;
  float: right;
  margin: -131px -37px -40px;
  color: #199094 !important;
  background-color: white;
  font-size: 25px;
  border-radius: 40px;
}
.extra-size {
  font-size: 19px !important;
}
/* .extra-size.ant-select-lg {
  padding: 16px 11px;
}
.extra-size.ant-picker-large {
  padding: 16px 11px;
} */
.extra-size.ant-input-lg {
  padding: 16px 11px;
  font-size: 19px !important;
}

.extra-size .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  padding: 11px 11px;
  align-items: center;
}

.extra-size .ant-select-multiple.ant-select-show-arrow .ant-select-selector, .ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  min-height: 60px;
}