.alert-shown {
    opacity: 1;
    transition: all 250ms linear;
  }
  
  .alert-hidden {
    opacity: 0;
    transition: all 250ms linear;
  }

  .closeiconstyle{
    background-color: blue;
  }