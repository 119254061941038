/* @import url("https://fonts.googleapis.com/css?family=AvenirBook"); */
/* @import './style/custom-antd.css'; */

@font-face {
  font-family: 'AvenirBook';
  src: url('https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/Avenir-Medium-09.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


body {
  font-family: 'AvenirBook' !important;
}



body {
  margin: 0;
  font-family: 'AvenirBook' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: "#F0F3F5";
}

/* @media (max-width: 1600px){
  body{
    transform: scale(0.7); 
    transform-origin: top left;
  }
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  font-size: 16px;
}

body a , body span , body p ,.h, h , div{
  font-family: 'AvenirBook';
}

.h,
 h {
  font-size: 16px;
}



/* input:active{
  border-color: #f6f8fa !important;
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2) !important;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2) !important;
} */

.ant-tooltip-inner {
  min-width: 30px !important;
  min-height: 32px !important;
  padding: 6px 8px !important;
  color: rgb(0, 0, 0) !important;
  text-align: left !important;
  text-decoration: none !important;
  word-wrap: break-word !important;
  background-color: #ffffff00 !important;
  border-radius: 4px !important;
  box-shadow: none !important;
  margin-bottom: -20px;
  margin-left: 5px;
  font-weight: bold;
  font-size: 16px;
}
.ant-tooltip{
  z-index: 1000 !important;
}
.ant-tooltip-arrow {
  display: none !important;
}

input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 0px 0px;
  color: #111111!important;
  font-family: 'AvenirBook';
  font-size: 16px !important;
  line-height: 21px !important;
  font-weight: 400;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
input:hover {
  border-color: #199094;
  border-left-width: 1px !important;
}

input:focus-within {
  border-color: #199094 !important;
  border-left-width: 1px !important;
}
input:focus {
  border-color: #199094 !important;
  border-left-width: 1px !important;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  display: contents;
}
.main-menu-level-0 .ant-menu-inline .ant-menu-item::after{
  display: none;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item, .ant-menu-sub.ant-menu-inline > .ant-menu-submenu.main-menu-level-1 > .ant-menu-submenu-title {
  padding-left: 30px !important;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item, .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
  list-style-position: inside;
  border-radius: 0px 50px 50px 0px;
  /* border-left: 3px solid #98d8d8; */
  list-style-type: disc;
  transition: background-color 0.3s ease;
  -webkit-tap-highlight-color: transparent;
  padding-left: 38px !important;
  padding-right: 22px !important;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
}
.main-menu-level-0 .ant-menu-sub.ant-menu-inline > .ant-menu-item{
  width: 104%;
}

.ant-picker-time-panel .ant-picker-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: auto;
  flex: auto;
  height: 55px !important;
}
/* 
.ant-btn:hover, .ant-btn:focus {
  color: #32AAB0 !important;
  background:#F9FAFC !important;
  border-color: #32AAB0 !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #DAF9FD !important;
}

.ant-menu-submenu-selected {
  color: #32AAB0;
}

.ant-menu-item-selected a, .ant-menu-item-selected a:hover {
  color: #32AAB0 !important;
}

.ant-menu-item a:hover {
  color: #32AAB0 !important;
} */

.ant-radio-checked .ant-radio-inner {
  border-color: #199094!important;
}

.ant-radio-inner::after {
  background-color: #199094!important;
}

/* Button css  */
/* with background  */

.button-main ,#signup_form_submit ,.ant-btn-lg, .ant-btn-primary,.ant-btn-lg{
  background-color: #199094; 
  font-family: 'AvenirBook';
  padding: 7px 8px ;
  border-radius: 8px ;
  font-size: 17px ;
  /* line-height: 21px ; */
  color: white!important ;
  border: none ;
  font-weight: 500 ;
  min-height: 30px;
  border: 1px solid #199094;
  transition: background-color 0.3s ease, color 0.3s ease;


}

/* .button-main:hover ,#signup_form_submit:hover ,.ant-btn-lg:hover, .ant-btn-primary:hover,.ant-btn-lg:hover{
  background-color: #16c1c7; 
  color: #fff ;
  border: 1px solid #16c1c7;
} */


/* without background  */


.button-secondary {
  background-color: #ffffff;
  font-family: 'AvenirBook';
  padding: 7px 8px;
  border-radius: 8px;
  color: #199094;
  border: 1px solid #199094;
  font-size: 17px;
  /* line-height: 16px; */
  font-weight: 500;
  min-height: 30px;
  transition: background-color 0.3s ease, color 0.3s ease;

}
/* .button-secondary:hover{
  background-color: #199094; 
  color: white!important ;

} */


.sum-sub-heading{
  color: #199094!important;
}

/* steps background fill  */


/* .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #199094!important;
} */
.ant-card-grid {
  border-radius: 12px !important;
}
/* plus svg hover */
.ant-card-grid-hoverable:hover .anticon svg {
  fill: #199094 !important;
}

.ant-form-item-explain{
  margin-top: 20px;
  margin-bottom: 10px;
 }


 /* @media screen and (max-width: 1780px) {
  .username-header-top{
    padding-left: 20px!important;
  }
 }
 

 @media screen and (max-width: 1380px) {
  .username-header-top{
    padding-left: 20px!important;
  }
 }
 @media screen and (max-width: 1280px) {
  .username-header-top{
    padding-left: 40px!important;
  }
 } */


 /* my target industries css start here */

.my-target-industry{
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 12px;
}

.my-target-industries-title{
  color: #111111;
  font-family: 'AvenirBook';
  font-size: 16px;
  line-height: 24px;
  font-weight: 800;
  font-size: 18px;
}

.my-target-role{
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 12px;
}

.my-target-roles-title{
  color: #111111;
  font-family: 'AvenirBook';
  font-size: 16px;
  line-height: 24px;
  font-weight: 800;
  font-size: 18px;
}

.inner-container-targetRole{
  margin: 24px 0px;
}


.inner-container-subtitle{
  padding: 10px 0px;
  color: #111111;
  font-family: 'AvenirBook';
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
}



.inner-container-with-button{
  display: flex;
  flex-direction: column;
  margin: 20px 0px;
}



.inner-container-subtitle-title{
  color: #111111;
  font-family: 'AvenirBook';
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
}

span.anticon.anticon-close.ant-modal-close-icon{
  color: white;
}
