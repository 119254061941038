

@media (max-width: 1600px){
  .parent-comp {
    transform: scale(0.8); 
    transform-origin: top left;
    width: 125%;
    height: 125%;
  }
}

@media (max-width: 1300px){
  .parent-comp {
    transform: scale(0.7); 
    transform-origin: top left;
    width: 142.8%;
    height: 142.8%;
  }
}
@media (max-width: 768px){
  .parent-comp {
    transform: scale(0.5); 
    transform-origin: top left;
    width: 200%;
    height: 200%;
  }
}
.already-signin{
  border:0 !important;
  outline:0 !important;
  color:#199094 !important;
  font: normal normal normal 10px/14px AvenirBook;

  margin-bottom: 20px;
}
.text-radio-button{
    margin: 0px 10px !important;
    font-size: 12px !important;
    /* border-radius: 5px !important; */
    font-weight: bold !important;
    height: 46px !important;
}

.width-label {
    width: 117px;
    padding-right:7px;
}
/* .login-form-button{
  margin-bottom:150px;
} */

.radio-input-element {
    display: none !important;
}

.radio-input {
    background-color: #FFFFFF;
    color: #199094;
    border: 1px solid #199094;
    text-align: center;
    font: normal normal normal 10px/14px AvenirBook;
    border-radius: 6px;
}

.radio-input:hover {
    cursor: pointer;
}

.radio-input-element:checked + .radio-input {
     box-shadow: 0 0 1px 1px #199094;
    background-color: #E5F2F3;
    border: 2px solid #199094;
    font: normal normal bold 10px/14px AvenirBook;
    color:#199094;
}
.radio-input-element:focus-within + .radio-input {
  box-shadow: 0 0 1px 1px #199094;
 background-color: #DFF6F7;
 border: 2px solid #199094;
 font: normal normal bold 10px/14px AvenirBook;
 color:#199094;
}

 inner-content{
     font: normal normal normal 10px/14px AvenirBook;
 }

 .week-label {
    width: 100%;
}

.week-input-element {
    display: none !important;
}

.week-input {
    background-color: #FFFFFF;
    color: #199094;
    border: 1px solid #199094;
    text-align: center;
    font: normal normal normal 10px/14px AvenirBook;
    border-radius: 6px;
}

.radio-input:hover {
    cursor: pointer;
}

.week-input-element:checked + .week-input {
     /* box-shadow: 0 0 1px 1px #2ecc71; */
     box-shadow: 0 0 1px 1px #199094;

    background-color: #DFF6F7;
    border: 2px solid #199094;
    font: normal normal bold 10px/14px AvenirBook;
    color:#199094;
 }

 .date-pick{
     margin: 10px 0 0 !important;
 }

/* =======================================================================  */
  .num-editable{
    opacity: 0;
    cursor: pointer;
  }
  
  .phone-inp-wrapper:hover .num-editable{
    opacity: 1;
    color:#199094;
  }
  
  .phone-inp-wrapper:active .num-editable{
    opacity: 1;
    color:#199094;
  }
  .phone-inp-wrapper:focus-within .num-editable{
    opacity: 1;
    color:#199094;
  }
  
  .phone-inp-wrapper:focus-within .phone-input-edit,.phone-input-edit-cc{
    opacity: 1;
    border-bottom: 1px solid #199094;
  }
  .phone-inp-wrapper:focus-within .phone-input-edit-cc{
    opacity: 1;
    border-bottom: 1px solid #199094;
  }
  .phone-input-edit input{
    color:#199094;
  }
  .phone-input-edit{
    display:flex;
  }
  .phone-input-edit-cc {
    color:#199094 !important;
    width:60px !important;
  }
  .privacy-head{
    text-align: left;
    letter-spacing: 0px;
    color: #646F7B;
  }
  .read-all{
    color:#199094;
    text-align: right;
    cursor: pointer;
  }
.pp-scrollable-div{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 2px solid #49CDE1;
  border-radius: 15px;
  padding: 20px;
  height: 70vh;
  margin-bottom: 10px;
  }
.pp-scrollable-div::-webkit-scrollbar-thumb{
  width: 19px;
  height: 10px;
  background: #49CDE1 0% 0% no-repeat padding-box;
  border-radius: 14px;
  overflow: scroll;
}
.pp-text{
  text-align: left;
  font: normal normal normal 20px/24px AvenirBook;
  letter-spacing: 0px;
  color: #707070;
  overflow-y: scroll;
  height: 65vh;
}
.pp-text, .pp-text p{
  text-align: left;
  font: normal normal normal 20px/24px AvenirBook;
  letter-spacing: 0px;
  color: #707070;
}
.pp-text .pp-text-heading{
  font-size: 18px;
}
.pp-text .pp-text-content{
  font-size: 16px;
}
.pp-text::-webkit-scrollbar-track{
  background: #DEDCDC 0% 0% no-repeat padding-box;
  border-radius: 14px;
  opacity: 0.44;
}
.pp-text::-webkit-scrollbar{
  display: block;
}
.pp-text::-webkit-scrollbar-button{
  display: none;
}
.pp-text::-webkit-scrollbar-thumb{
  width: 19px;
  height: 70px;
  background: #49CDE1 0% 0% no-repeat padding-box;
  border-radius: 14px;
}
#signup_form .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector{
  height: 36px;
}
#signup_form .ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input{
  height: 34px;
}
.ant-select-single .ant-select-selector{
  align-items: center;
}
  
