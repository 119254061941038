
@media (max-width: 1600px){
    .modal-comp {
      transform: scale(0.8); 
      transform-origin: top left;
      width: 125%;
      height: 125%;
    }
  }
  
  @media (max-width: 1300px){
    .modal-comp {
      transform: scale(0.7); 
      transform-origin: top left;
      width: 142.8%;
      height: 142.8%;
    }
  }
  @media (max-width: 768px){
    .modal-comp {
      transform: scale(0.5); 
      transform-origin: top left;
      width: 200%;
      height: 200%;
    }
  }
.ant-popover-content p {
  margin: 0;
}
.site-card-wrapper {
  padding: 0px;
  background: #CCE6E7;
}

.info-col{
  border: 1px solid #35ACB1;
  text-align: center;
}

.close-btn{
  font: 'normal normal bold 16px/44px AvenirBook';
  color: #32AAB0;
  text-align: right !important;
}
.ant-modal-close{
    outline:0 !important;
}
.content-radio{
    width: 250px;
    text-align: center;
}


.new-label {
    width: 100%;
}

.card-input-element {
    display: none;
}

.card-input {
    background-color: #FFFFFF;
    color: #199094;
    border: 1px solid #199094;
    text-align: center;
    font: normal normal normal 20px/12px AvenirBook;
    border-radius: 1px;
    /* width: 325px;
    height: 167px; */
}

.card-input:hover {
    cursor: pointer;
}

.card-input-element:checked + .card-input {
     box-shadow: 0 0 1px 1px #2ecc71;
    background-color: #CCE6E7;
    border: 2px solid #199094;
    font: normal normal 600 20px/12px AvenirBook;
    color:#199094;
 }

.svg-space{
    padding:36px 0;
}

.content-space{
    padding-bottom: 50px;
}

.triangle2 {
    border-top: 75px solid transparent;
    border-right: 106px solid #CCE6E7;
    color: #CCE6E7;
    position: absolute;
    top: -61px;
    right: 16px;
}


/* .parent-antd .ant-popover {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    top: 96px !important;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1030;
    font-weight: normal;
    white-space: normal;
    text-align: left;
    cursor: auto;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
} */

.close-icon {
    /* position: absolute !important; */
    color: white !important;
    padding: 6px !important;
    background-color: #199094 !important;
    border-radius: 21px !important;
    /* margin: -15px 0 0px 15px !important; */
    font-size: 20px;
  }
  .close-icon:active {
    /* position: absolute !important; */
    color: white !important;
    padding: 6px !important;
    background-color: #199094 !important;
    border-radius: 21px !important;
    /* margin: -15px 0 0px 15px !important; */
    font-size: 20px;
}

.slider-question{
    font: normal normal normal 16px/44px AvenirBook;
    padding-top: 50px;
    text-align: left;
}

