.App {
  text-align: center;
  height: 100vh;
}
@media (max-width: 2000px){
  .ant-modal {
    transform: scale(0.8); 
    transform-origin: top !important;
    /* width: 125%; */
  }
}

@media (max-width: 1240px){
  .ant-modal{
    transform: scale(0.7); 
    transform-origin: top !important;
    /* width: 142.8%; */
  }
}
@media (max-width: 1024px){
  .ant-modal{
    transform: scale(0.6); 
    transform-origin: top !important;
    /* width: 166.67%; */
  }
}
@media (max-width: 868px){
  .ant-modal{
    transform: scale(0.5); 
    transform-origin: top !important;
    /* width: 200%; */
  }
}


.hidden {
  display: none !important;
}

/* @media (max-width: 1600px){
  .App{
    transform: scale(0.7); 
    transform-origin: top left;
  }
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.impersonating-wrapper {
  text-align: right;
  margin: 14px 20px 0px 0;
}

.impersonating-wrapper > span {
  background: white;
  padding: 6px 10px;
  border-radius: 10px;
  line-height: 18px;
  font-size: 14px;
}

.impersonating-wrapper > span > span {
  font-weight: bold;
  cursor: pointer;
  color: #69c4c8;
}

.pp-parent{
  background-color: #199094;
}
.pp-parent>.pp-text{
  padding: 27px;
    height: 100vh;
    transform: scale(0.9);
    background: white;
    border-radius: 18px;
}

.pp-text::-webkit-scrollbar-thumb {
  width: 19px;
  /* height: 70px; */
  background: #199094 0% 0% no-repeat padding-box !important;
  border-radius: 8px;
}
.link-privacy-policy{
color: #199094;
}

.ant-btn-primary {
  color: #fff !important;
  background: #49CDE1;
  font-weight: bold !important;
  border-color: #49CDE1;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-steps-item-active .ant-steps-item-title {
  font-weight: 700 !important;
}

/* .ant-btn-lg {
  color: #199094;
  height: 40px;
  padding: 6.4px 15px;
  font-size: 16px;
  border: 1px solid #199094 !important;
  border-radius: 2px;
} */


.ant-input, .ant-select-selector .ant-tag.ant-tag-has-color, .ant-select-selector, .ant-select-dropdown, .ant-picker{
  border-radius: 8px !important;
  font-size: 17px !important;
}
.ant-input:hover, .ant-select-selector:hover{
  border-color: #199094 !important;
}
.ant-select-selector .ant-tag.ant-tag-has-color{
  background-color: #199094 !important;
}
/* .ant-btn {
  height: 32px;
  padding: 7px 8px;
  font-size: 16px;
  font-family: "AvenirBook" !important;
  border-radius: 8px !important;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-color: #d9d9d9;
  line-height: 16px;
} */
p{
  font-family: "AvenirBook" !important;
  color: #111111;
}
a{
  color: #199094 !important
}
.ant-btn-primary {
  color: #fff !important;
  background: #199094!important;
  border-color: #199094!important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045) !important;
}
.ant-btn-lg {
  height: 40px !important;
  padding: 8px !important;
  font-size: 16px !important;
  line-height: 21px!important;
}
.ant-btn-dashed{
  background: #fff !important;
  border-color: #d9d9d9 !important;
  border-style: dashed !important;
}

.resend{
  display: contents !important;
  color: #199094 !important;
  font-size: 14px !important;
}
.ant-slider-handle{
  border: solid 2px #199094 !important;
  background-color: #199094 !important;
  width: 15px !important;
  height: 15px !important;
}
.ant-slider-rail, .ant-slider-track, .ant-slider-step{
  height: 5px !important;
}
.ant-slider-track{
  background-color: #199094 !important
}
.ant-slider-handle.ant-tooltip-open{
    border: solid 2px #199094 !important;
    background-color: #199094
}
.ant-card{
  color: #111111 !important;
  border-radius: 12px !important;
  font-size: 16px !important;
}
.ant-card .month, .ant-card .commit-hours-sec{
  font-size: 17px !important;
}
.ant-card a {
  font-size:  16px;
}
/* .ant-card-body{
  line-height: 1.8 !important;
} */
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon{
  border-color: #199094 !important;
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon{
  color: #111111 !important;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon{
  color: #199094 !important;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title, .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle, .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-description{
  color: #199094 !important;
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
  color: #111111 !important;
}
.ant-steps-item-wait .ant-steps-item-icon{
  border-color: #111111 !important;
}

/* 07 Jan 2025 */
.ant-modal-content{
  border-radius: 12px !important;
}
.ant-modal-header{
  border-radius: 12px 12px 0 0 !important;
}
.ant-popover-inner{
  border-radius: 12px !important;
}
.ant-progress-success-bg, .ant-progress-bg{
  background-color: #199094 !important;
}
/* .ant-select{
  color: #111111 !important;
} */
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner{
  background-color: #199094 !important;
}
.anticon.anticon-delete svg path[fill='#e6f7ff']{
  fill: #e5f2f3;
}
.anticon.anticon-delete svg path[fill='#1890ff']{
  fill: #199094;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after{
  background-color: #199094 !important;
}
.ant-select-multiple .ant-select-selection-item{
  border-radius: 8px !important;
  background-color: #199094 !important;
  color: white;
  margin: 14px 5px;
  padding: 2px 15px !important;
}
.ant-select-multiple .ant-select-selection-item-remove > .anticon{
  vertical-align: text-top !important;
}
.ant-select-multiple .ant-select-selection-item .ant-select-selection-item-remove{
  color: white;
}
.ant-dropdown-menu{
  border-radius: 12px !important;
}
.ant-dropdown-menu-item > a, .ant-dropdown-menu-submenu-title > a{
  color: #111111 !important;
}

.element-hide-temporary{
  display: none !important;
}
button:focus, input:focus-visible{
  outline: 0 !important;
}
/* checkbox resume  */
.custom-cb .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #199094!important;
}
.ant-input-affix-wrapper>input.ant-input {
  font-family: 'AvenirBook';
}
.ant-badge-count {
  min-width: 23px !important;
  height: 23px !important;
  line-height: 24px !important;
  border-radius: 12px !important;
}
.ant-badge-count p {
  font-size: 16px;
  color: #fff;
}
/* .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after{
  background-color: #111111;
} */
.ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after{
  background-color: #A8A5A5 !important;
}
.close-icon {
  /* position: absolute !important; */
  color: white !important;
  padding: 6px !important;
  background-color: #199094 !important;
  border-radius: 21px !important;
  /* margin: -15px 0 0px 15px !important; */
  font-size: 20px;
}
.close-icon:active {
  /* position: absolute !important; */
  color: white !important;
  padding: 6px !important;
  background-color: #199094 !important;
  border-radius: 21px !important;
  /* margin: -15px 0 0px 15px !important; */
  font-size: 20px;
}
.ant-steps-item.ant-steps-item-error span.anticon.anticon-close.ant-steps-error-icon{
  position: relative;
  bottom: 14%;
}
.ant-steps-item.ant-steps-item-finish span.anticon.anticon-check.ant-steps-finish-icon{
  position: relative;
  bottom: 14%;
}