.learning-header{
    text-align: left;
    font: normal normal 600 18px/22px AvenirBook;
    padding:40px 0 35px;
    color: #199094;
}

.col-headerA{
    text-align: left;
    font: normal normal 600 17px/22px AvenirBook;
    letter-spacing: 0px;
    color: #2B3E3E;
    margin: 0px 0 4px !important;
}

.col-subhead{
    text-align: left;
    font: normal normal normal 16px/17px AvenirBook;
    color: #587B7B;
    margin: 4px 0 5px !important;
}

.col-width{
    width: 19% !important;
    margin:0 0.5% !important;
    text-align: center !important;
    border: 1px solid white !important;
    box-shadow: none !important;
    padding: 0 !important;
}

.add-new-btn{
    width: 100% !important;
    padding: 10px !important;
    cursor: pointer !important;
    margin: 3px 0 !important;
    /* box-shadow: 0 1px 0px 0px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.09) !important; */

}

.add-new-btn:hover .plus-icon{
    color: #49CDE1 !important;
}

.learning-card{
    width: 100% !important;
    margin: 7px 0 !important;
    padding: 12px !important;
    cursor: pointer !important;
    /* border-bottom: 2px solid green; */
    box-shadow: 0 1px 0px 0px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.09) !important;
}
.learning-content{
    word-break: break-all;
    text-align: left;
    font: normal normal normal 17px AvenirBook;
    color: #000000;
}
    
.learning-content-label{
    text-align: left;
    font: normal normal normal 16px AvenirBook;
    color: #193347;
    margin-bottom: 5px;
}

.interest-level-text{
    text-align: left;
    font: normal normal 600 16px/12px AvenirBook;
    letter-spacing: 0px;
}

.role-tag{
    border-radius: 12px !important;
    font: normal normal 600 15px AvenirBook !important;
    max-width: 100% !important;
}

.role-list{
    padding-bottom: 8px !important;
    text-align: left ;
}

.learning-card:hover .learning-content{
    text-decoration: underline !important;
}
div.custom-card-content {
    overflow: auto;
    scrollbar-width: auto;
    -ms-overflow-style: none;
}
div.custom-card-content::-webkit-scrollbar {
    width: 5px;
}
div.custom-card-content::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 6px;
}
div.custom-card-content::-webkit-scrollbar-track {
    background: #f0f0f0;
    border-radius: 6px;
}
div.custom-card-content::-webkit-scrollbar-button {
    display: none;
}