.resume-head{
    margin: 70px 0 30px;
    text-align: left;
    font: normal normal 600 18px/22px AvenirBook;
    letter-spacing: 0px;
    color: #296B70;
}
.res-div{
    margin-bottom:40px;
    opacity:1
}
.res-div:hover,.res-div:focus-within{
    opacity: 1;
}
.res-div-head{
    font: normal normal 600 17px/22px AvenirBook;
    letter-spacing: 0px;
    color: #111111;
}
.res-div-subhead{
    font: normal normal normal 16px/22px AvenirBook;
    letter-spacing: 0px;
    color: #199094;
    border-bottom: solid 1px #111111;
    margin-bottom: 20px;
}
.ques-checkbox-div{
    display:flex;
    padding:12px 0;
    position: relative;
}
.ques-checkbox-container {
    position: relative;
  }
/* .container{
    display: block;
  position: relative;
  padding-left: 35px;
  font: normal normal normal 16px/22px AvenirBook;
    letter-spacing: 0px;
    color: #606368;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin:0;
}
.ques-checkbox {
    position: absolute;
    top: 10px;
    opacity: 0;
    cursor: pointer;
    height: 20px;
    width: 20px;
    z-index: 10;
  }

  .checkbox {
    position: absolute;
    top: 0px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border-radius:50%;
    border:1px solid #AEB7C2; 
    cursor: pointer;
  }

.checkbox:after {
content: "";
position: absolute;
display: none;
left: 8px;
top: 3px;
width: 5px;
height: 10px;
border: solid white;
border-width: 0 3px 3px 0;
-webkit-transform: rotate(45deg);
-ms-transform: rotate(45deg);
transform: rotate(45deg);
}
  
  .ques-checkbox:checked ~ .checkbox {
    background-color: #CCD6E1;
  }
 .ques-checkbox:checked + .checkbox:after {
    display: block;
  }
  .ques-checkbox:checked ~ span {
    text-decoration: line-through;
    text-decoration-color: #CCD6E1;
    color:#CCD6E1;
    cursor: default;
    pointer-events: none;
}
.ques-checkbox:checked ~ span{
    border:0 !important;
    pointer-events: none;
} */

/* CSS for Custom Checkbox */

.custom-cb{
  padding: 12px 0;
  font: normal normal normal 16px/22px AvenirBook;
  letter-spacing: 0px;
  color: #606368;
}
.custom-cb label{
  font: normal normal normal 17px/22px AvenirBook !important;
  letter-spacing: 0px;
  color: #606368;
}
.custom-cb .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.custom-cb .ant-checkbox-wrapper .ant-checkbox:hover .ant-checkbox-inner,
.custom-cb .ant-checkbox-wrapper .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #aeb7c2;
}
.custom-cb .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ccd6e1;
  border: 0;
}
.custom-cb .ant-checkbox-wrapper .ant-checkbox .ant-checkbox-input {
  display:none;
}
.custom-cb .ant-checkbox-wrapper .ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 20px;
  height: 20px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #aeb7c2;
  border-radius: 50%;
  border-collapse: separate;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.custom-cb .ant-checkbox-wrapper .ant-checkbox-checked::after {
  border: 0;
}
.custom-cb .ant-checkbox-wrapper-checked span {
  text-decoration: line-through;
    text-decoration-color: #CCD6E1;
    color:#CCD6E1;
}
.custom-cb .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 30%;
  display: table;
  border: 3px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}

.custom-cb .ant-checkbox-wrapper .ant-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 24%;
  display: table;
  width: 6.714286px;
  height: 10.142857px;
  border: 3px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}


