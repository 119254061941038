.collapse-btn {
  font-size: x-large;
  z-index: 10;
  position: absolute;
  margin-left: 7px;
  /* margin-top: 2%; */
  /* top: 4%; */
}
.collapse-btn span.make-white{
  position: relative;
  bottom: 100px;
}

.select-heading {
  font: normal normal bold 17px/22px AvenirBook;
  letter-spacing: 0px;
  color: #111111;
}

.separator {
  background: 0% 0% no-repeat padding-box padding-box rgb(181, 184, 204);
  padding-top: 1px;
  margin-top: 11px;
  border: 2px solid rgb(240, 240, 240);
}

.thin-separator {
  background: 0% 0% no-repeat padding-box padding-box rgb(181, 184, 204);
  border: 1px solid rgb(240, 240, 240);
  width: 100%;
}
.left-content{
  width: 100%;
  font: normal normal normal 16px/16px AvenirBook;
  font-size: 17px !important;
  word-break: break-all;
  border:1px solid rgb(0 0 0 / 17%) !important;
}

.left-content > .ant-card-body{
  padding: 9px;
}

.ant-input, .ant-select-selector, .ant-select-dropdown, .ant-picker{
  border-radius: 12px !important;
}
.ant-select .ant-select-selector:not(:has(.ant-tag.ant-tag-has-color)){
  height: 45.14px;
}
.ant-select .ant-select-selector .ant-tag.ant-tag-has-color{
  margin: 8px 5px !important;
}