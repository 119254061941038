/* .woken-progress {
  display: flex;
  height: 8px;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.woken-progress .progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
} */
.div-my-career-roadmap-title{
  display: flex;
  justify-content: start;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  font-size: 18px;
}
.my-career-roadmap-add-new-phase-button{
  width: 120px;
  height: 32px;
  padding: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.39px;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 4px;
  box-shadow: 0px 2px 0px 0px #0000000B;
}
.woken-progress {
  width: 100%;
}

.woken-progress .today {
  position: relative;
}

.woken-progress .today .marker {
  display: inline-block;
  height: 64px;
  width: 2px;
  position: absolute;
  background: #199094;
  top: 20px;
  left: 0;
}

.woken-progress .today .title {
  color: #199094;
  margin-left: -22px;
  text-transform: uppercase;
}

.woken-progress .section {
  height: 44px;
}

.woken-progress .timeline .portion {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.woken-progress .timeline .portion span {
  background-color: white;
  position: relative;
}

.woken-progress .timeline.title .portion {
  background-color: white;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.woken-progress .timeline .space {
  display: inline-block;
  margin-left: 0;
  width: 11.111111%;
}

.woken-progress .timeline:not(.title) {
  background-color: white;
  height: 33px;
  border-top: 1px dashed #CCE6E7;
  display: flex;
}

.woken-progress .timeline .exploration.portion {
  height: 100%;
  transition: width 0.6s ease;
  border-right: 1px solid #329CA0;
  background: linear-gradient(90deg, rgba(7, 150, 161, 0.2) 0%, rgba(255, 255, 255, 0.2) 183.46%);
  font-size: 17px;
  padding: 5px 16px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.woken-progress .timeline .job-search.portion {
  height: 100%;
  transition: width 0.6s ease;
  border-right: 1px solid #329CA0;
  background: linear-gradient(90deg, rgba(3, 83, 90, 0.2) 0%, rgba(255, 255, 255, 0.2) 183.46%);
  font-size: 17px;
  padding: 5px 16px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
@media (max-width: 768px) {
  .woken-progress .timeline .exploration.portion, .woken-progress .timeline .job-search.portion {
    font-size: 17px;
  }
}

.woken-progress .today,
.woken-progress .months.section .month {
  display: inline-block;
  width: 11.111111%;
}
.woken-progress .months.section{
  /* height: 28px; */
  display: flex;
  justify-content: space-between;
  gap: 12px;
  margin-top: 18px;
}

