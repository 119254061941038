.research-card {
  width: 30%;
  margin: 1.4% 1.2%;
  height: 406px;
  border-top: 10px solid #ccd6e1;
  box-shadow: 0px 3px 16px 0px #1313131A;
}

.research-card:hover {
  border-top: 10px solid #ffca3e;
  box-shadow: 0px 3px 16px 0px #1313131A;
}

.research-card:focus-within {
  border-top: 10px solid #199094;
  background: #f5fffe 0% 0% no-repeat padding-box;
}
.research-card-focus-within {
  border-top: 10px solid #199094;
  background: #e5f2f3 0% 0% no-repeat padding-box;
  width: 30%;
  margin: 1.4% 1.2%;
  height: 406px;
  box-shadow: 0px 3px 16px 0px #1313131A;
  
}

.card-heading {
  font: normal normal bold 17px/22px Montserrat;
  text-align: left;
}

.research-card-low {
  width: 30%;
  margin: 1.4% 1.2%;
  height: 406px;
  border-top: 10px solid rgb(29 186 255 / 37%);
  box-shadow: 0px 3px 16px 0px #1313131A;
}

.research-card-low:hover {
  border-top: 10px solid #ffca3e;
  box-shadow: 0px 3px 16px 0px #1313131A;
}

.research-card-low:focus-within {
  border-top: 10px solid #1dbaff;
  background: #f2fbff 0% 0% no-repeat padding-box;
}
.research-card-low-focus-within {
  border-top: 10px solid #1dbaff;
  background: #f2fbff 0% 0% no-repeat padding-box;
  width: 30%;
  /* margin: 1.4% 1.2%; */
  height: 406px;
  box-shadow: 0px 3px 16px 0px #1313131A;
}

.card-heading-low {
  font: normal normal bold 17px/22px Montserrat;
  text-align: left;
  color: #587b7b;
}

.half-card-container {
  width: 30%;
  padding: 0;
  margin: 1.4% 1.2%;
  height: 406px;
  /* border: px solid #ffffff00; */
  box-shadow: 0px 3px 16px 0px #1313131A;
}
.half-card {
  width: 100%;
  margin: 0;
  height: 190px;
  box-shadow: 0px 3px 16px 0px #1313131A;
  padding: 0px;
  /* margin: 1.2% 1.2%; */
  /* border-top: 10px solid #CCD6E1; */
}

.half-card-low {
  width: 100%;
  margin: 0;
  height: 190px;
  box-shadow: 0px 3px 16px 0px #1313131A;
  padding: 0px;
  /* margin: 1.2% 1.2%; */
  /* border-top: 10px solid rgb(29 186 255 / 37%); */
}

.text-underline {
  border-bottom: 1px dashed #ccd6e1;
}

.text-underline:hover {
  border-bottom: 1px solid #6dc6ca;
}

.text-underline:focus {
  border-bottom: 1px solid #6dc6ca;
}

/* .cr {
    display: none;
  }
  .item-div{
    display:flex; 
    justify-content:space-between; 
    align-items:center;
      width: 100%;
  }
  .cr-label {
    font-size: 16px;
    display: -webkit-inline-box;
    overflow: hidden;
  }
  .span-name{
    white-space: nowrap;
    font: normal normal normal 12px/14px Montserrat;
    color: #111111;
  }
  .cr-span {
    border: 1px solid #111111;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    text-align: center;
    margin: 0 6px 0;
    display: block;
    font: normal normal normal 14px/12px Montserrat;
    color: #111111;
    opacity: 0.9;
    
  }
  .cr:checked ~ .cr-label {
    color: #32aab0;
    font: normal normal 600 14px/12px Montserrat;
  }
  .cr:checked ~ .cr-label .cr-span {
    background-color: #dff6f7;
    border: 2px solid #32aab0 !important;
    font: normal normal 600 14px/12px Montserrat;
    color: #32aab0;
  }
  
  .cr:checked ~ .span-name{
    color: #32aab0;
    font: normal normal 600 14px/12px Montserrat;
  } */

.cr {
  display: none;
}
.item-div {
  display: flex;
  justify-content: space-between;
  /* align-items:center; */
  /* width: 100%; */
  /* new */
  z-index: 1;
  background: white;
}
/* .item-div::after{
    position: absolute;
    top: 16px;
    left: 100%;
    display: block;
    width: 9999px;
    height: 1px;
    background: #f0f0f0;
    content: '';
  } */
.cr-label {
  font-size: 18px;
  display: -webkit-inline-box;
  overflow: hidden;
  /* new */
  padding: 0 5px 0 0px;
}
.span-name {
  white-space: nowrap;
  padding: 4px 0;
}
.cr-span {
  border: 1px solid #111111;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  text-align: center;
  margin: 0 6px 0;
  display: block;
  padding: 2px 0;
  color: #111111;
  font-weight: bold;
}
.cr-span-submit {
  border: 1px solid #111111;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  text-align: center;
  margin: 0 6px 0;
  display: block;
  padding: 2px 0;
}
.cr-span-submit {
  border: 1px solid #111111;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  text-align: center;
  margin: 0 6px 0;
  display: block;
  padding: 2px 0;
}

/* .cr-span::after{
    
  } */

/* .span-name::after{
    position: absolute;
    top: 16px;
    left: 100%;
    display: block;
    width: 9999px;
    height: 1px;
    background: #f0f0f0;
    content: '';
  } */
.cr:checked ~ .cr-label {
  /* color: #32aab0; */
  font-weight: bold;
}
.cr:checked ~ .cr-label .cr-span {
  background-color: #199094 !important;
  color: #ffffff !important;
  font-weight: bold;
  color: #111111;
  border: 0px !important;
}
.cr:checked ~ .cr-label .cr-span-submit {
  /* background-color: #dff6f7; */
  border: 2px solid #32aab0 !important;
}

.hr-gap {
  margin-right: 3px;
  width: 20%;
  margin-left: 5px;
}

.del-submitted {
  width: 19%;
  margin: 4%;
  float: right;
}

.line-between {
  background: #d8d5d5;
  height: 1px;
  /* z-index: -99; */
  top: 14px;
  margin: 0 10px;
  position: relative;
}

div.horizontal-scroll-hide-arrows {
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: auto;
  -ms-overflow-style: none;
}

div.horizontal-scroll-hide-arrows::-webkit-scrollbar {
  height: 12px;
  
}

div.horizontal-scroll-hide-arrows::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 6px;
}

div.horizontal-scroll-hide-arrows::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 6px;
}

div.horizontal-scroll-hide-arrows::-webkit-scrollbar-button {
  display: none;
}