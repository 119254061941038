.resume-head{
    margin: 70px 0 30px;
    text-align: left;
    font: normal normal 600 18px/22px AvenirBook;
    letter-spacing: 0px;
    color: #296B70;
}
.res-div{
    margin-bottom:40px;
    opacity: 1
}
.res-div:hover,.res-div:focus-within{
    opacity: 1;
}
.res-div-head{
    font: normal normal 600 17px/22px AvenirBook;
    letter-spacing: 0px;
    color: #111111;
}
.res-div-subhead{
    font: normal normal normal 16px/22px AvenirBook;
    letter-spacing: 0px;
    color: #199094;
    border-bottom: solid 1px #111111;
    margin-bottom: 20px;
}
.ques-checkbox-div{
    display:flex;
    padding:12px 0;
    position: relative;
}
.ques-checkbox-container {
    position: relative;
  }
.container{
    display: block;
  position: relative;
  padding-left: 35px;
  /* margin-bottom: 12px; */
  font: normal normal normal 16px/22px AvenirBook;
    letter-spacing: 0px;
    color: #606368;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin:0;
  
}
.ques-checkbox {
    position: absolute;
    top: 10px;
    opacity: 0;
    cursor: pointer;
    height: 20px;
    width: 20px;
    z-index: 10;
  }

  .ques-input{
    text-align: left;
    font: normal normal normal 16px/20px AvenirBook;
    letter-spacing: 0px;
    color: #404040;
    padding: 0;
  }
  .ques-input input{
    border-bottom: solid 1px !important;
    font: normal normal normal 16px/20px AvenirBook;
    color: #440044;
  }

  /* Create a custom checkbox */
  .checkbox {
    position: absolute;
    top: 0px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border-radius:50%;
    border:1px solid #AEB7C2; 
    cursor: pointer;
  }
  
  /* On mouse-over, add a grey background color */
  /*.container:hover input ~ .checkbox {
    background-color: #ccc;
  }
  */
 
  /* Create the checkbox/indicator (hidden when not checked) */
      /* Style the checkbox/indicator */
.checkbox:after {
content: "";
position: absolute;
display: none;
left: 7px;
top: 4px;
width: 5px;
height: 10px;
border: solid white;
border-width: 0 3px 3px 0;
-webkit-transform: rotate(45deg);
-ms-transform: rotate(45deg);
transform: rotate(45deg);
}
  
  /* When the checkbox is checked, add a blue background */
  .ques-checkbox:checked ~ .checkbox {
    background-color: #CCD6E1;
  }
 
  /* Show the checkbox when checked */
 .ques-checkbox:checked  + .checkbox:after {
    display: block;
  }
  .ques-checkbox:checked ~ span {
    text-decoration: line-through;
    text-decoration-color: #CCD6E1;
    color:#CCD6E1;
    cursor: default;
    pointer-events: none;
}
.ques-checkbox:checked ~ .ques-input input{
  color:#CCD6E1;
  cursor: default;
  pointer-events: none;
}



.linkedIn-inp{
  padding: 12px 0 0;
    font-size: 16px;
    color: #606368;
    margin-left: -30px;
    border: 0;
    border-bottom: solid 1px #CCD6E1 !important;
    outline: 0;
}
.linkedIn-inp:focus-within{
    border: 0;
    border-bottom: solid 1px #CCD6E1 !important;
    outline: 0;
}
.linkedIn-inp input{
    font-size: 16px;
    color: #606368;
    border-bottom: solid 1px #CCD6E1 !important;
}
.line-inp{
  padding: 11px 0;
    font-size: 16px;
    color: #606368;
    margin-left: -20px;
  text-decoration: line-through;
    text-decoration-color: #CCD6E1;
    color:#CCD6E1;
    cursor: default;
    pointer-events: none;
}
.line-inp input{
    font-size: 16px;
    color: #606368;
    text-decoration: line-through;
    text-decoration-color: #CCD6E1;
    color:#CCD6E1;
    cursor: default;
    pointer-events: none;
}
