.contact-sel-heading{
    padding: 50px 0;
    font: normal normal 600 14px/22px AvenirBook;
    letter-spacing: 0px;
    color: #296B70;
}
.add-contact-heading{
    text-align: left;
    font: normal normal bold 20px/12px AvenirBook;
    letter-spacing: 0px;
    color: #606368;
    padding: 0;

}

.tracker-subtext{
    font: normal normal normal 12px/18px AvenirBook;
    letter-spacing: 0px;
    color: #AEB7C2;
    padding:0 0 60px;
}

.contact-list-scroll{
    height: 54vh;
    overflow-y: scroll;
}

.go-btn{
    width: 100%;
    margin: 30px 0;
}

.tracker-head{
    text-align: left;
    font: normal normal 800 18px AvenirBook;
    letter-spacing: 0px;
    color: #199094;
    padding: 50px 0 30px;
}

.curr-mission{
    background: #FFCA3E36 0% 0% no-repeat padding-box;
    border: 1px solid #FFCA3E;
    padding: 2%;
    margin-bottom: 30px;
}

.col1-text{
    font: normal normal bold 18px AvenirBook;
    letter-spacing: 0px;
    color: #3D3D3D;
}

.col2-head{
    font: normal normal bold 18px AvenirBook;
    letter-spacing: 0px;
    color: #111111;
    text-align: center;
    margin-bottom: 3px;
}

.col2-text{
    font: normal normal 600 16px AvenirBook;
    letter-spacing: 0px;
    color: #3D3D3D;
    text-align: center;
    margin-bottom: 2px;
}

.popup {
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  left: 0px;
  list-style-type: none;
  margin: 0;
  outline: none;
  padding: 0;
  position: absolute;
  text-align: left;
  top: 0px;
  overflow: hidden;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.popup li {
  clear: both;
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  margin: 0;
  padding: 5px 12px;
  transition: all .3s;
  white-space: nowrap;
  -webkit-transition: all .3s;
}

.popup li:hover {
  background-color: #e6f7ff;
}

.popup li > i {
  margin-right: 8px;
}

.col-1{
    background-color: #199094 !important;
    color:white !important;
    font-weight: bold !important;
}
.filter-dropdown{
    color:white;
    font-weight: bold !important;
    width:220px;
}
.ant-table-filter-column-title{
    font-size: 20px;
}
.ant-table thead{
    font-size: 20px !important;
}
.ant-table, .ant-table input{
    font-size: 18px !important;
}
.minimized .ant-input,.minimized .ant-select-selector .ant-tag.ant-tag-has-color,.minimized .ant-select-selector, .minimized .ant-select-dropdown, .minimized .ant-picker{
    font-size: 18px !important;
}
.minimized .ant-table a, .minimized .ant-table .ant-select-selection-item{
    font-size: 18px !important;
}
.ant-table-filter-trigger .filter-dropdown:hover, .ant-table-filter-trigger .filter-dropdown:focus-within{
    color:#111111 !important;
}
.ant-table-filter-trigger-container-open, .ant-table-filter-trigger-container:hover, .ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container:hover{
    background: #e5f2f3;
}
.ant-table-filter-trigger {
    font-size: 14px;
}
.ant-table-filter-trigger:hover, .ant-table-filter-trigger:focus-within {
    color: #111111 !important;
    font-size: 14px;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item{
    color: #111111 !important;
    text-align: center !important;
}

.option-style{
    max-width: 100%;
}

.row-style{
    padding:50px 50px;
}

.no-space{
    padding: 10px !important;
    text-align: center;
    font-weight: bold !important;
}

.row-btn{
    text-align: center !important;
    cursor: pointer !important;
    color:#AEB7C2 !important;
    font-size: 20px;
}

.row-btn:hover{
    color:#199094 !important;
}

.contact-head-dr{
    font-size: 16px;
    letter-spacing: 0px;
    margin: 5px 0;
}

.col-gap{
background: #606368;
margin: 0 0 20px;
}

.subcol{
    font: normal normal normal 14px/22px AvenirBook;
letter-spacing: -0.36px;
color: #AEB7C2;
}

.subcol-val{
    font: normal normal normal 14px/22px AvenirBook;
letter-spacing: 0px;
color: #606368;
}

/* .subcol-val:hover, .subcol-val:focus-within{
    border-bottom: 1px solid #ACE3E6;
} */

.contact-name-head{
    text-align: left;
    font: normal normal bold 20px/12px AvenirBook;
    letter-spacing: 0px;
    color: #606368;
}

.drawer-subhead{
    text-align: left;
    font: normal normal normal 12px/18px AvenirBook;
    letter-spacing: 0px;
    color: #587B7B;
}

.drawer-title{
    margin: 20px 10px 10px;
}

.info-type{
    font: normal normal normal 12px/14px AvenirBook;
letter-spacing: -0.36px;
color: #AEB7C2;
margin: 6px 0;
}

.info-value{
    font: normal normal normal 14px/22px AvenirBook;
    color: #606368;
}