.select-heading {
  font: normal normal bold 17px/22px AvenirBook;
  letter-spacing: 0px;
  color: #111111;
}

.separator {
  background: 0% 0% no-repeat padding-box padding-box rgb(181, 184, 204);
  padding-top: 1px;
  margin-top: 11px;
  border: 2px solid rgb(240, 240, 240);
}

.thin-separator {
  background: 0% 0% no-repeat padding-box padding-box rgb(181, 184, 204);
  border: 1px solid rgb(240, 240, 240);
  width: 100%;
}

.assessment-row {
  opacity: 1;
}

.assessment-row:hover {
  opacity: 1;
}

.assessment-row:focus {
  opacity: 1;
}

.assessment-row:focus-within {
  opacity: 1;
}
.assessment-row:hover > .del-assessment{
  color: red;
  opacity: 0.7;
}
.del-assessment{
  opacity: 0;
  font-size: 24px;
  float: right;
  cursor: pointer;
}

.del-assessment:hover{
  color: red;
  opacity: 0.7;
}

.ant-input, .ant-select-selector, .ant-select-dropdown, .ant-picker{
  border-radius: 12px !important;
}
.ant-select .ant-select-selector:not(:has(.ant-tag.ant-tag-has-color)){
  height: 45.14px;
}
.ant-select .ant-select-selector .ant-tag.ant-tag-has-color{
  margin: 8px 5px !important;
}