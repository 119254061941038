.headerimage {
  /* background-image: url(./../../Assets/back.png); */
  background-repeat: no-repeat;
  background-size: contain;
  /* clip-path: polygon(0 0, 100% 0, 100% 32%, 0 10%);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 32%, 0 10%); */
  /* width:100%;
    top:-20px;
    left:0; */
}

@media (max-width: 1600px){
  .headerimage {
    transform: scale(0.8); 
    transform-origin: top left;
    width: 125%;
  }
}

.headerimage-coachdashboard {
  background-image: url(./../../Assets/back.png);
  background-repeat: no-repeat;
  background-size: contain;
  /* clip-path: polygon(0 0, 100% 0, 100% 32%, 0 10%);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 32%, 0 10%); */
  /* width:100%;
    top:-20px;
    left:0; */
}


.res-width-left{
  width: 15%;
}
.ant-layout-sider.res-width-left{
  width: 15% !important;
  min-width: 15% !important;
  max-width: 15% !important;
}
.res-width-right{
  width: 85%;
}
@media (max-width: 1440px){
  .res-width-left{
    width: 15%;
  }
  .ant-layout-sider.res-width-left{
    width: 15% !important;
    min-width: 15% !important;
    max-width: 15% !important;
  }
  .res-width-right{
    width: 85%;
  }
}

@media (max-width: 1024px){
  .res-width-left{
    width: 16%;
  }
  .ant-layout-sider.res-width-left{
    width: 16% !important;
    min-width: 16% !important;
    max-width: 16% !important;
  }
  .res-width-right{
    width: 84%;
  }
}

@media (max-width: 768px){
  .res-width-left{
    width: 18%;
  }
  .ant-layout-sider.res-width-left{
    width: 18% !important;
    min-width: 18% !important;
    max-width: 18% !important;
  }
  .res-width-right{
    width: 82%;
  }
}

 
/* @media (max-width: 1600px){
  .headerimage-coachdashboard {
    transform: scale(0.8);
    transform-origin: top left;
    width: 125%;
  }
} */

@media (max-width: 1240px){
  .headerimage{
    transform: scale(0.7); 
    transform-origin: top left;
    width: 142.8%;
  }
}
@media (max-width: 1024px){
  .headerimage{
    transform: scale(0.6); 
    transform-origin: top left;
    width: 166.67%;
  }
}
@media (max-width: 868px){
  .headerimage{
    transform: scale(0.5); 
    transform-origin: top left;
    width: 200%;
  }
}


.navtabs {
  font-weight: bold;
  font-size: 14px;
  color: white;
}
.ant-menu {
  font-size: 15px !important;
}
.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 34px;
  font-weight: '500';
  font-size: '15px';
  line-height: "22px";
  width: '208px' !important;
  margin-bottom: 0px !important;
}
.menu-item {
  border-right: none;
  border-radius: 20px;
}
.ant-menu-submenu-title {
  margin-top: 0 !important;
  padding: 12px !important;
  display: flex !important;
  align-items: center !important;
  gap: 10px;
}
.ant-menu-submenu-title span{
  display: flex;
  gap: 12px;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
}
  
.menu-item {
  border-right: none;
  border-radius: 20px;
}

.badge-1 {
  background-color: rgb(131, 117, 235);
  box-shadow: 0 0 0 1px rgb(131, 117, 235);
  margin-top: -5px;
}

.badge-2 {
  background-color: #ffa86b;
  box-shadow: 0 0 0 1px #ffa86b;
  margin-top: -5px;
}

.make-white {
  color: white !important;
  background-color: #00000059;
  border-radius: 20px;
  border: 1px solid #00000000;
  cursor: pointer;
}

.ti-dropdown {
  transform: scale(0.9);
  min-width: 370px;
  padding: 2%;
}
.ant-menu-item.locked a{
  color: #B0B0B0 !important;
}
/* Left progres bar */
li.ant-menu-item, li.ant-menu-submenu, ul.ant-menu-sub > p {
  border-left: solid 10px transparent;
}

ul.ant-menu-sub > p, li.ant-menu-item {
  margin-left: -10px;
  margin-top: 0 !important;
  margin-bottom: 5px !important;
  color: #FFFFFF
}

ul.ant-menu-sub>p {
   margin-top: 8px !important; 
}

li.ant-menu-submenu.onboarding, li.ant-menu-submenu.onboarding .ant-menu-item-selected  {
  border-left-color: #111111;
  /* border-right: 10px solid transparent; */
}
 li.ant-menu-submenu.exploration, li.ant-menu-submenu.exploration .ant-menu-item-selected  {
  border-left-color: #111111;
  /* border-right: 10px solid transparent; */
}

li.ant-menu-submenu.execution, li.ant-menu-submenu.execution .ant-menu-item-selected {
  border-left-color: #111111;
  /* border-right: 10px solid transparent; */
}
li.ant-menu-submenu.resources, li.ant-menu-submenu.resources .ant-menu-item-selected {
  border-left-color: #111111;
  /* border-right: 10px solid transparent; */
}

.ant-menu-item.ongoing {
  border-left-color: #111111 !important;
}
li.ant-menu-item, li.ant-menu-submenu, ul.ant-menu-sub > p{
  border-left: 0px;
}


/* .ant-menu-item.ongoing::before{
    content: url('https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/menuOngingSmall.svg');
}

.ant-menu-item.ongoing.ant-menu-item-selected::before{
  border-left-color: #111111;
  content: url('https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/menuOngingSmall.svg');
  
}

li.ant-menu-item.ongoing::before {
  position: relative;
  content: url('https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/menuOngingSmall.svg');
  margin-left: -16px;
  top: 4px;
}

li.ant-menu-item.done::before {
  position: relative;
  content: url('https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/menuDoneSmall.svg');
  margin-left: -16px;
  top: 4px;
}
.ant-menu-item.done.ant-menu-item-selected::before{
  border-left-color: #111111;
  content: url('https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/menuDoneSmall.svg');
}

li.ant-menu-item.review::before {
  position: relative;
  content: url('https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/menuReviewSmall.svg');
  margin-left: -16px;
  top: 4px;
}
.ant-menu-item.review.ant-menu-item-selected::before{
  border-left-color: #111111;
  content: url('https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/menuReviewSmall.svg');
}

li.ant-menu-item.just-launched::before {
  position: absolute;
  content: url('');
  margin-left: -16px;
  top: 9px;
  background-image: url('https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/menuUnlockedSmall.svg');
  background-size: 100%;
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
}
.ant-menu-item.just-launched.ant-menu-item-selected::before{
  border-left-color: #111111;
  content: url('');
  background-image: url('https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/menuUnlockedSmall.svg');
  background-size: 100%;
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
}

li.ant-menu-item.unlocked::before {
  position: absolute;
  content: url('');
  margin-left: -16px;
  top: 9px;
  background-image: url('https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/menuUnlockedSmall.svg');
  background-size: 100%;
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
}
.ant-menu-item.unlocked.ant-menu-item-selected::before{
  border-left-color: #111111;
  content: url('');
  background-image: url('https://django-woken-static.s3.us-east-2.amazonaws.com/static/media/menuUnlockedSmall.svg');
  background-size: 100%;
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
}

.ant-menu-item.just-launched {
  border-left-color: #c677e3 !important;
}

li.ant-menu-item.locked,
li.ant-menu-submenu.locked,
ul.ant-menu-sub > p.locked {
  border-left-color: #ccd6e1;
  cursor: not-allowed;
}

li.ant-menu-item.locked > a,
li.ant-menu-submenu.locked > .ant-menu-submenu-title,
ul.ant-menu-sub > p.locked {
  color: #ccd6e1;
  cursor: not-allowed;
  pointer-events: none;
}

.ant-menu-inline .ant-menu-item::after {
  border-right: transparent !important;
} */

.just-released {
  text-transform: uppercase;
  background-color: #199094;
  padding: 5px 10px;
  border-radius: 10%;
  color: white;
  font-weight: bold;
  font-size: 10px;
  margin-left: 10px;
}

.ant-col-3 {
  max-width: 12.5% !important;
}
.ant-col-21 {
  max-width: 85.5% !important;
}
.ant-menu{
  color: #ffffff !important;
}
.ant-menu-item a{
  color: #ffffff !important;
}
ul.ant-menu.ant-menu-root.ant-menu-inline{
  background-color: #111111 !important;
}
.ant-menu-sub.ant-menu-inline{
  background-color: #111111 !important;
  padding-left: 12px !important;
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after, .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after, .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after, .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after{
  background-image: linear-gradient(to right, rgb(255 255 255 / 85%), rgb(255 255 255 / 85%)) !important;
}
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after{
  background-image: linear-gradient(to right, rgb(255 255 255 / 85%), rgb(255 255 255 / 85%)) !important;
}
/* .sitelogo{
  background-color: #111111;
  color: #199094;
  font-size: 32px;
  text-align: left;
  padding: '15px' !important;
  width: 208px;
  height: 40px;
  gap: 5.3px
} */

.ant-menu-item:active, .ant-menu-submenu-title:active {
  background: #111111;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #111111 !important;
}

li.main-menu-level-0.ant-menu-submenu-open.ant-menu-submenu-inline > div {
  background: rgba(25, 144, 148, 0.3);
  border-radius: 8px;
}
/* background: rgba(25, 144, 148, 0.3);
border-radius: 8px; */
.ant-menu-item-selected a{
  color: #7FC1C4 !important;
}
.ant-menu-submenu-selected {
  color: #ffffff !important;
} 
li.ant-menu-item.ant-menu-item-only-child{
  /* padding-left: 22px !important; */
  border-left: 0px solid transparent;
  margin-left: 0;
  padding: 0px !important;
  margin-bottom: 5px !important;
  /* border-right: 10px solid transparent; */
}
li.ant-menu-item.ant-menu-item-only-child[title="DASHBOARD"] a:focus::before, li.ant-menu-item.ant-menu-item-only-child[title="CALENDAR"] a:focus::before{
  background: rgba(25, 144, 148, 0.3) !important;
  border-radius: 8px !important;
}
.username-header-top a{
  color:#111111 !important;
}
.main-menu-level-1.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  transform: rotate(45deg) translateY(-2px) !important;
}
.main-menu-level-1.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateY(2px) !important;
}
.main-menu-level-1.ant-menu-submenu-open.ant-menu-submenu-inline{
  position: relative;
}
.main-menu-level-1.ant-menu-submenu-inline .ant-menu.ant-menu-sub.ant-menu-inline{
  position: absolute;
  left: 100%;
  z-index: 999;
}
.main-menu-level-1.ant-menu-submenu-inline.ant-menu-submenu-open .ant-menu.ant-menu-sub.ant-menu-inline{
  left: 104%;
}
.main-menu-level-1.ant-menu-submenu-open ul.ant-menu-sub > p, .main-menu-level-1.ant-menu-submenu-open li.ant-menu-item{
  margin-left: 0px
}
.main-menu-level-1.ant-menu-submenu-open ul{
  padding: 12px;
}
@media screen and (min-height: 823px) {
  .ant-modal-wrap:has(form#signup_form){
    overflow: hidden;
  }
}
/* @media screen and (max-width: 1850px) {
  .ant-modal-wrap:has(form#signup_form){
    overflow: hidden;
  }
} */
/* .ant-modal-wrap:has(form#signup_form){
  overflow: hidden;
} */
/* top bar container css */

/* .top-bar-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
}

.user-name{
  font-size: 20px;
  color: #373737;
  line-height: 27px;
  font-weight: 800;
  font-family: 'AvenirBook';
  margin: 0px;
}
.right-icon-container{
  display: flex;
  justify-content: center;
  align-items: center;
  gap:16px;
}

.customize-dashboard{
  border: 1px solid #199094;
  border-radius: 8px;
  padding: 12px 24px;
  background-color: white;
  color: #199094;
  font-family: 'AvenirBook';
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
} */
 