.ant-steps-item-process .ant-steps-item-icon {
    background: #ffffff;
    border-color: #111111;
}
.ant-steps-item-process .ant-steps-item-icon .ant-steps-icon {
    color: #111111;
}
.networking-nav.net-ref .ant-steps-item-process:not(.ant-steps-item-active) .ant-steps-item-icon{
    background-color: #ffffff;
    border-color: #111111;
}
.networking-nav.net-ref .ant-steps-item-process:not(.ant-steps-item-active) .ant-steps-item-icon .ant-steps-icon{
    color: #111111;
}
.networking-nav.net-ref.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon{
    border-color: #199094 !important;
}
.networking-nav.net-ref.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
    color: #199094 !important;
}