.cl-subhead{
    text-align: left;
    font: normal normal normal 18px/22px AvenirBook;
    letter-spacing: 0px;
    color: #199094;
    margin-bottom: 40px;
}
.cl-head{
    text-align: left;
    font: normal normal 600 18px/22px AvenirBook;
    letter-spacing: 0px;
    color: #199094;
    margin-top: 55px;
}
.ant-form-item-label label{
    padding: 0 10px 0 0;
    font-size: 17px !important;
}
.inline-block-label label{
    display: inline-block !important;
    padding: 0 10px 0 0;
    font-size: 17px !important;
}
.cl-title{
    font: normal normal normal 17px/22px AvenirBook;
    letter-spacing: 0px;
    color: #111111;
}
.cl-subtext, .cl-subtext div p{
    font: normal normal normal 16px/22px AvenirBook;
    letter-spacing: 0px;
    color: #587B7B;
    margin-bottom: 25px;
}
.cl-g-head{
    text-align: center;
font: normal normal bold 20px/12px AvenirBook;
letter-spacing: 0px;
margin-bottom: 20px;
display:flex;
justify-content: space-between;

}
.download-cl{
    background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255) !important;
    border: 1px solid #199094 !important;
    border-radius: 3px;
    font: bold 16px / 44px AvenirBook;
    color: #199094 !important;
    float: right;
    height: 42px;
    padding: 0 20px;
    margin-top: -15px;
}
.cl-pdf-wrap{
    background: #CCE6E7 0% 0% no-repeat padding-box;
    padding:100px 150px;
}
.pdf-body{
    /* padding: 50px;
    background-color: #ffffff;
    color: #606368; */
    padding: 16px;
  background-color: rgb(255, 255, 255);
  height: 100%;
  width:100%;
  margin: 8px;
  color: #606368;
}
.cl-pdf-title{
    text-align: center;
font: normal normal 600 20px/22px AvenirBook;
letter-spacing: 0px;
color: #606368;
}
.cl-subtitle{
    text-align: center;
font: normal normal normal 14px/22px AvenirBook;
letter-spacing: 0px;
color: #606368;
}
.cl-para{
    text-align: left;
font: normal normal normal 14px/26px AvenirBook;
letter-spacing: 0px;
margin-bottom: 20px;
color: #606368;
}
.cl-para span,.cl-pdf-text{
    text-align: left;
font: normal normal normal 14px/26px AvenirBook;
letter-spacing: 0px;
color: #606368;
}
.cl-inline-form_item{
    display:inline-block !important;
    margin:-2px 0 0 !important;
    font: normal normal normal 14px/26px AvenirBook;
    letter-spacing: 0px;
}
.cl-form_item{
    margin:-4px 0 0;
    font: normal normal normal 14px/26px AvenirBook;
    letter-spacing: 0px;
}
.cl-inline-inp{
    text-align: left;
font: normal normal normal 14px/26px AvenirBook;
letter-spacing: 0px;
color: #32AAB0;
padding:0 !important;
/* margin-top: -7px; */
}
.cl-inline-inp input{
    text-align: left;
font: normal normal normal 14px/26px AvenirBook;
letter-spacing: 0px;
color: #32AAB0;
width:inherit;
padding:0 0 0 5px!important;
/* margin-top: -7px; */
}
.cl-edit{
    background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255) !important;
    border: 1px solid rgb(73, 205, 225) !important;
    border-radius: 3px;
    font: bold 16px / 44px AvenirBook;
    color: rgb(73, 205, 225) !important;
    float: left;
    height: 42px;
    padding: 0 20px;
}
.cl-pdf-viewer{
    width:inherit;
    height: 1020px;

}
.cl-pdf-viewer::-webkit-scrollbar {
    display: none;
  }
.editable-cl-wrap{
    background-color: #ffffff;
    padding: 50px;
}